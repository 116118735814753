import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    ViewChild,
    ElementRef,
    OnInit,
    DestroyRef,
    inject,
} from '@angular/core';
import { tap, take, takeUntil } from 'rxjs/operators';
import { removeDiacritics } from '@shared/utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterOption, FilterOptionType } from '@shared/models/filter';
import _ from 'lodash';
import CropType from '@shared/models/crop-type';
import { LayersService } from '@core/services/layers.service';

@Component({
    selector: 'map-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.less'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
    destroyRef = inject(DestroyRef);

    @Input()
    fitBoundingBox: Function;

    @ViewChild('dropdown') dropdown: any;

    constructor(
        private layersService: LayersService,
        private elementRef: ElementRef,
    ) {}

    prefixFilterOptions: Array<{ label: string; value: FilterOption }> = [];
    cropTypeFilterOptions: Array<{ label: string; value: FilterOption }> = [];
    fieldNameFilterOptions: Array<{ label: string; value: FilterOption }> = [];

    currentFilters = [];

    ngOnInit() {
        this.initializeData();
    }

    ngOnDestroy() {}

    /**
     * Visually removes the current filters (if any) in the UI element.
     * Functionally, the filters (layersService.currentFilters) are reset just before
     * a new layer is switched
     */
    public resetCurrentFilters = () => {
        this.currentFilters = [];
    };

    /**
     * Populates the search bar with the crop types and field names in the current layer
     */
    initializeData() {
        this.layersService.Fields.pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((fields) => {
                if (!_.isEmpty(fields)) {
                    const cropTypes: CropType[] = this.layersService.extractCropTypes(fields);

                    // Clear previous options
                    this.prefixFilterOptions = [];
                    this.cropTypeFilterOptions = [];
                    this.fieldNameFilterOptions = [];

                    // Populate prefix filters
                    const prefixes: Set<string> = new Set<string>([]);

                    for (const field of fields) {
                        const index: number = field.name.indexOf(' ');

                        if (index !== -1 && field.name.startsWith('BF')) {
                            const prefix: string = field.name.slice(0, index);
                            prefixes.add(_.trim(prefix));
                        }
                    }

                    prefixes.forEach((prefix) => {
                        this.prefixFilterOptions.push({
                            label: prefix,
                            value: {
                                type: FilterOptionType.Prefix,
                                value: prefix,
                            },
                        });
                    });

                    // Populate crop type filters
                    _.forEach(cropTypes, (cropType) =>
                        this.cropTypeFilterOptions.push({
                            label: cropType.name,
                            value: {
                                type: FilterOptionType.CropType,
                                value: cropType.name,
                            },
                        }),
                    );

                    // Populate field filters
                    _.forEach(fields, (field) =>
                        this.fieldNameFilterOptions.push({
                            label: field.name || field.id.toString(),
                            value: {
                                type: FilterOptionType.FieldName,
                                value: field.name || field.id,
                            },
                        }),
                    );
                }
            }),
        ).subscribe();
    }

    onFilterChanged(filterOptions: FilterOption[]): void {
        this.layersService.currentFilters.next(filterOptions);

        const boundingBox = this.layersService.getBoundingBoxOfFieldsIds(
            this.layersService.filterCurrentFields(filterOptions),
        );

        if (this.fitBoundingBox) {
            _.isEmpty(filterOptions) ? this.fitBoundingBox() : this.fitBoundingBox(boundingBox);
        }
    }

    /**
     * Compares if a string contcains another one, ignoring diacritics
     */
    compare = (value, option) =>
        removeDiacritics(option.nzValue.value).includes(removeDiacritics(value));
}
