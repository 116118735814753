<nz-dropdown-menu #yearmenu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
        <li
            nz-menu-item
            [nzSelected]="availableYear === selectedYear"
            *ngFor="let availableYear of availableYears"
            (click)="changeSelectedYear(availableYear)"
        >
            Anul {{ availableYear }}
        </li>
    </ul>
</nz-dropdown-menu>

<ng-template #popoverContent>
    <div class="Popover-Stats">
        <fields-stats [fieldStats]="fieldStats" setCompactMode></fields-stats>
    </div>
</ng-template>

<ng-template #popoverTitle>
    <div class="Popover-Title">
        <div>Culturi anul {{ selectedYear }}</div>
        <button nz-button (click)="exportStats()" [nzLoading]="isDownloading">
            <i nz-icon nzType="cloud-download" nzTheme="outline"></i>
        </button>
    </div>
</ng-template>

<div class="Map-Wrapper">
    <map-overview-map-view #mapView></map-overview-map-view>

    <div class="Right-Toolbox">
        <div class="Clients-Stats Flex-Column Right-Item">
            <div class="Flex-Row Clients-Row">
                <div>Total clienți</div>
                <div class="Value Weight-500">{{ clientsCount }}</div>
            </div>

            <div *ngIf="clientsLoaded < clientsCount" class="Flex-Row Clients-Row">
                <div class="Label Flex-Center-All">
                    <div class="Dot Green"></div>
                    Încărcați
                </div>
                <div class="Value Weight-500">{{ clientsLoaded }}</div>
            </div>

            <div *ngIf="clientsFailed" class="Flex-Row Clients-Row">
                <div class="Label Flex-Center-All">
                    <div class="Dot Red"></div>
                    Neîncărcați
                </div>
                <div class="Value Weight-500">{{ clientsFailed }}</div>
            </div>
        </div>

        <button
            class="Refresh-Button Left-Item"
            (click)="refreshData()"
            nz-button
            nz-type="primary"
            nzShape="normal"
            [nzLoading]="clientsLoaded + clientsFailed < clientsCount"
            [disabled]="clientsLoaded + clientsFailed < clientsCount"
        >
            <i nz-icon nzType="sync"></i>
        </button>

        <map-zoom-buttons
            class="Zoom-Buttons"
            [handleZoomIn]="mapView.zoomIn"
            [handleZoomOut]="mapView.zoomOut"
        >
        </map-zoom-buttons>
    </div>

    <div class="Top-Left-Toolbox">
        <button
            nz-button
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="yearmenu"
            [nzDisabled]="clientsLoaded + clientsFailed < clientsCount"
            class="Year-Selector Left-Item"
        >
            Anul {{ selectedYear }} <i nz-icon nzType="down"></i>
        </button>

        <button
            nz-button
            nz-popover
            [nzPopoverTitle]="popoverTitle"
            nzPopoverTrigger="click"
            [nzPopoverContent]="popoverContent"
            nzPopoverPlacement="bottomLeft"
            class="Stats-Button Right-Item"
            [disabled]="isLoadingStats"
        >
            <i nz-icon nzType="bar-chart"></i>
        </button>
    </div>

    <map-center-buttons
        class="Center-Buttons"
        [handleFitFields]="mapView.fitBoundingBox"
    ></map-center-buttons>
</div>
