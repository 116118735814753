<div class="Wrapper">
    <button (click)="toggleIsolationMode(false)" nz-button nzSize="small" nzType="danger">
        Anulează
    </button>

    <button
        class="Margin-Top"
        (click)="resetDrawingInteractions()"
        nz-button
        nzSize="small"
        nzType="default"
    >
        Șterge poligonul
    </button>

    <button
        class="Margin-Top"
        (click)="intersectWithField()"
        nz-button
        nzSize="small"
        nzType="primary"
    >
        Intersectează
    </button>

    <button
        class="Margin-Top Green-Button"
        (click)="openModal()"
        nz-button
        nzSize="small"
        nzType="danger"
    >
        Salvează
    </button>
</div>

<nz-modal
    nzWrapClassName="vertical-center-modal"
    [(nzVisible)]="isModalVisible"
    nzTitle="Salvează poligonul"
    (nzOnCancel)="isModalVisible = false"
    [nzContent]="modalContent"
    [nzFooter]="modalFooter"
>
</nz-modal>

<ng-template #modalContent>
    <div>
        <div class="Modal-Item-Wrapper">
            <div class="Modal-Item-Title-Container">
                <div class="Modal-Item-Title">Denumire</div>
                <span class="Asterisk">*</span>
            </div>

            <input
                class="Modal-Item"
                nz-input
                nzPlaceHolder="Denumire"
                [(ngModel)]="child.name"
                name="nume"
                maxlength="64"
                required
                autofocus
            />
        </div>

        <div class="Modal-Item-Wrapper">
            <div class="Modal-Item-Title-Container">
                <div class="Modal-Item-Title">Detalii</div>
                <span class="Asterisk">*</span>
            </div>

            <textarea
                class="Modal-Item"
                nz-input
                placeholder="Introdu detaliile daunei"
                [(ngModel)]="child.details"
                [nzAutosize]="{ minRows: 2, maxRows: 6 }"
            ></textarea>
        </div>

        <map-mini-map-view [inputField]="child" padding="large"></map-mini-map-view>
    </div>
</ng-template>

<ng-template #modalFooter>
    <button nz-button nzType="default" (click)="isModalVisible = false">Anulează</button>
    <button nz-button nzType="primary" (click)="savePolygon()" [nzLoading]="isSaving">
        Salvează
    </button>
</ng-template>
