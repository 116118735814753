import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { defaults as olInteractionDefaults } from 'ol/interaction.js';
import olMap from 'ol/Map';
import olView from 'ol/View';
import olLayerImage from 'ol/layer/Image';
import olLayerGroup from 'ol/layer/Group';
import olGeomPoint from 'ol/geom/Point';
import { switchMap, take } from 'rxjs/operators';
import _ from 'lodash';
import { LayerType } from '@shared/models/map/layer';
import { LayersService } from '@core/services/layers.service';
import { MapService } from '@core/services/map.service';
import Field from '@shared/models/field';
import CropType from '@shared/models/crop-type';
import { Extent } from 'ol/extent';
import { Observable, of } from 'rxjs';
import { NdviData } from '@shared/models/map/ndvi';
import { Feature as olFeature } from 'ol';

@Component({
    selector: 'map-mini-map-view',
    templateUrl: './mini-map-view.component.html',
    styleUrls: ['./mini-map-view.component.less'],
    animations: [
        trigger('loadingAnimation', [
            transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class MiniMapViewComponent implements OnInit, OnChanges {
    static mapsCount = 0;

    @Input()
    set enableLoadingScreen(value: Boolean) {
        this.isLoadingScreenVisible = value.toString() !== 'false';
    }

    @Input()
    set hideBingMapsLayer(value: Boolean) {
        this.addBingMaps = !(value.toString() !== 'false');
    }

    /**
     * Map container styles
     */
    @Input()
    containerStyle: any;

    @Input()
    loadingScreenStyle: any;

    @Input()
    isActive: boolean;

    /**
     * fieldId that will be rendered
     */
    @Input()
    fieldId: any;

    /**
     * If a field is provided, we use it for rendering instead of the provided fieldId
     */
    @Input()
    inputField: Field;

    @Input()
    layerType = LayerType.Outline;

    /**
     * If a tilesURL is provided, the minimap will use it to request NDVI imagery
     */
    @Input()
    tilesURL: string;

    /**
     * The padding used to fit the bounding box of the fields. Can be 'small' or 'large'. Default is 'medium'
     */
    @Input()
    padding = 'medium';

    /**
     * Pin to be shown on map. If the property is set, the map will be centered on the pin feature.
     */
    @Input()
    pinCoordinates: string;

    /**
     * Used to change the cropType of the only displayed field at initialization.
     * If the property is set, the cropType will be set before the map's layerGroup is loaded.
     */
    @Input()
    cropType: CropType;

    isLoadingScreenVisible = true;
    currentContainerStyle = {};
    mapId: number = MiniMapViewComponent.mapsCount++;

    private field: Field;
    private map: olMap;
    private view: olView;
    private addBingMaps = true;

    private layerTypes = {};
    private pinFeature: olFeature;

    constructor(
        private layersService: LayersService,
        private mapService: MapService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pinCoordinates && this.map) {
            this.pinCoordinates = changes.pinCoordinates.currentValue;
            this.loadLayerGroup();
        }

        if (changes.isActive && this.currentContainerStyle) {
            this.currentContainerStyle['border'] = changes.isActive.currentValue
                ? '2px solid #00f3ff'
                : this.containerStyle.border;
        }
    }

    ngOnInit() {
        Object.assign(this.currentContainerStyle, this.containerStyle);

        // If a child field is provided, we use it for rendering instead of the provided fieldId
        if (this.inputField) {
            this.field = this.inputField;
            setTimeout(() => this.initializeFixedMap());
        } else {
            const filteredFields: Field[] = _.filter(
                this.layersService.FieldsAndChildren,
                (field) => field.id === Number.parseInt(this.fieldId),
            );

            if (!_.isEmpty(filteredFields)) {
                this.field = filteredFields[0];
                setTimeout(() => this.initializeFixedMap());
            } else {
                console.error('[MiniMapView] fieldId invalid or not provided!');
            }
        }
    }

    initializeFixedMap() {
        if (this.cropType) {
            this.field.apia_crop_type = this.cropType.id;
        }

        const viewOptions = {
            center: [26.1025, 44.3268] as [number, number],
            zoom: 16,
            minZoom: 6,
            maxZoom: 18,
            projection: 'EPSG:4326',
            constrainOnlyCenter: true,
            showFullExtent: true,
        };

        if (!this.pinCoordinates) {
            viewOptions['extent'] = this.layersService.getBoundingBoxOfPolygonCoordinates(
                this.field.geometry,
            );
        } else {
            this.pinFeature = this.layersService.readFeature(this.pinCoordinates);
        }

        this.view = new olView(viewOptions);

        this.map = new olMap({
            view: this.view,
            layers: [],
            controls: [],
            interactions: olInteractionDefaults({
                dragPan: false,
                keyboard: false,
                altShiftDragRotate: false,
                doubleClickZoom: false,
                mouseWheelZoom: false,
                shiftDragZoom: false,
                pinchRotate: false,
                pinchZoom: false,
            }),
            target: `mini-map-View-Container-${this.mapId}`,
        });

        this.loadLayerGroup();
    }

    private loadLayerGroup(): void {
        if (this.pinCoordinates) {
            this.pinFeature = this.layersService.readFeature(this.pinCoordinates);
        }

        const obtainLayerGroup = (): Observable<olLayerGroup> => {
            const fields: Field[] = [this.field];

            switch (this.layerType) {
                case LayerType.Vegetatie:
                    /**
                     * Only the tilesURL is used for NDVI data
                     */
                    return of(
                        this.mapService.createVegetationLayerGroup(fields, {
                            tilesURL: this.tilesURL,
                        }),
                    );

                // Outline
                default:
                    return of(
                        this.mapService.createOutlineLayerGroup(fields, {
                            addBingMaps: this.addBingMaps,
                            pinFeature: this.pinFeature,
                        }),
                    );
            }
        };

        obtainLayerGroup()
            .pipe(take(1))
            .subscribe((layerGroup: olLayerGroup) => {
                this.map.setLayerGroup(layerGroup);
                this.map.updateSize();

                this.layerTypes[this.layerType] = layerGroup;

                const mainLayersCount: number = layerGroup.get('mainLayersCount');

                if (mainLayersCount) {
                    if (this.pinFeature) {
                        this.view.fit(this.pinFeature.getGeometry() as olGeomPoint, {
                            padding: [40, 40, 40, 40],
                            maxZoom: 15,
                        });
                    } else {
                        this.fitBoundingBox(
                            this.view,
                            this.layersService.getBoundingBoxOfLayerGroup(layerGroup),
                        );
                    }
                }

                if (this.layerType === LayerType.Outline) {
                    setTimeout(() => (this.isLoadingScreenVisible = false), 500);
                }

                for (const layer of layerGroup.getLayers().getArray()) {
                    if (layer instanceof olLayerImage) {
                        setTimeout(() => {
                            layer.changed();
                            setTimeout(() => (this.isLoadingScreenVisible = false), 250);
                        }, 500);
                    }
                }
            });
    }

    private fitBoundingBox(view: olView, extent: Extent) {
        let paddingValue: number;

        switch (this.padding) {
            case 'small':
                paddingValue = 7.5;
                break;

            case 'large':
                paddingValue = 40;
                break;

            case 'huge':
                paddingValue = 60;
                break;

            default:
                paddingValue = 15;
                break;
        }

        const fitOptions = {
            size: this.map.getSize(),
            constrainResolution: false,
            padding: [paddingValue, paddingValue, paddingValue, paddingValue],
        };

        view.fit(extent, fitOptions);
    }
}
