<div class="Popup" [class.Offset]="isNdviVisible" [ngStyle]="containerStyle">
    <div class="Content" *ngIf="popupInfo">
        <span class="Name">{{ popupInfo?.name }}</span>
        <span class="CropType" [ngStyle]="cropNameStyle">{{ popupInfo?.type.name }}</span>
        <span *ngIf="!isAreaDisabled" class="Area"
            ><span *ngIf="popupInfo.isAreaComputed">*</span
            >{{ popupInfo?.area | number: '1.0-2' }} ha</span
        >
    </div>
</div>
