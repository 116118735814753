import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { PermissionsService } from '@core/services/permissions.service';
import { Observable, of } from 'rxjs';
import { take, switchMap, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OverviewGuard  {
    constructor(private router: Router, private permissionsService: PermissionsService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkActivation();
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkActivation();
    }

    checkActivation(): Observable<boolean> {
        const permissions: string[] = this.permissionsService.Permissions;
        if (permissions.length) {
            if (!permissions.includes('core.view_overview_map')) {
                this.router.navigateByUrl('/acces-restrictionat');
                return of(false);
            }

            return of(true);
        }

        // If the permissions weren't loaded yet
        return this.permissionsService.getPermissions().pipe(
            switchMap((permissions) => {
                if (!permissions.includes('core.view_overview_map')) {
                    return of(false);
                }
                return of(true);
            }),
            catchError((err) => of(false))
        );
    }
}
