import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { take } from 'rxjs/operators';
import { LayersService } from '@core/services/layers.service';
import { MapPopupInfo } from '@shared/models/map/map-popup-info';

@Component({
    selector: 'map-popup',
    templateUrl: './map-popup.component.html',
    styleUrls: ['./map-popup.component.less'],
})
export class MapPopupComponent implements OnChanges, OnInit {
    @Input()
    popupInfo: MapPopupInfo;

    @Input()
    containerStyle: any;

    @Input()
    set disableArea(value: Boolean) {
        this.isAreaDisabled = value.toString() !== 'false';
    }

    @Input()
    isNdviVisible = false;

    cropNameStyle: any;
    isAreaDisabled = false;

    ndviScore: number;

    /**
     * Chart-related variables
     */
    labels: string[] = ['Slab', 'Sub medie', 'Mediu', 'Bun', 'Performant'];

    options: any = {
        responsive: true,
        aspectRatio: 1,
        legend: {
            display: false,
        },
        segmentShowStroke: false,
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
    };

    chartData: number[] = [];
    colorsArray: string[] = ['red', 'darkorange', 'yellow', 'yellowgreen', 'green'];

    colors = [
        {
            backgroundColor: this.colorsArray,
        },
    ];

    legendItems = [];

    // currentLayerType: LayerType = this.layersService.currentLayerItem.getValue().layerType;

    // LayerType = LayerType;

    constructor(private layersService: LayersService) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.popupInfo) {
            this.popupInfo = changes.popupInfo.currentValue;
            if (this.popupInfo) {
                // const currentLayerItem: LayerItem = this.layersService.currentLayerItem.getValue();
                // this.currentLayerType = currentLayerItem.layerType;
                // if (currentLayerItem && currentLayerItem.layerType === LayerType.NDVI) {
                //     if (!_.isEmpty(this.popupInfo)) {
                //         this.layersService
                //             .getNdviScore(this.popupInfo.lastAvgNdvi, this.popupInfo.type.id)
                //             .pipe(take(1))
                //             .subscribe(score => (this.ndviScore = score), error => console.error(error));
                //     }
                // }

                this.cropNameStyle = {
                    color:
                        changes.popupInfo.currentValue.type.field_color === '#000000'
                            ? '#ffffff'
                            : changes.popupInfo.currentValue.type.field_color,
                };

                // const bins: number[] = this.popupInfo.lastHistNdvi;
                // if (bins) {
                //     this.chartData = bins;
                //     const total = _.sum(bins);
                //     const items = [];
                //     for (let i = 0; i < bins.length; i++) {
                //         if (bins[i]) {
                //             items.push({
                //                 index: i,
                //                 name: this.labels[i],
                //                 value: (bins[i] / total) * 100,
                //                 color: this.colorsArray[i]
                //             });
                //         }
                //     }
                //     this.legendItems = _.orderBy(items, ['value'], ['desc']);
                // }
            }
        }
    }
}
