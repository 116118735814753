import CropType from '../crop-type';

type MapPopupInfoProps = {
    name?: string;
    type?: CropType;
    area?: number;
    lastAvgNdvi?: number;
    lastHistNdvi?: number[];
    isAreaComputed?: boolean;
};

export class MapPopupInfo {
    name: string;

    type: CropType;

    area: number;

    isAreaComputed?: boolean;

    lastAvgNdvi?: number;

    lastHistNdvi?: number[];

    constructor({
        name,
        type,
        area,
        lastAvgNdvi,
        lastHistNdvi,
        isAreaComputed,
    }: MapPopupInfoProps) {
        this.name = name;
        this.type = type;
        this.area = area;
        this.lastAvgNdvi = lastAvgNdvi;
        this.lastHistNdvi = lastHistNdvi;
        this.isAreaComputed = isAreaComputed;
    }
}
