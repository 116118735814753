import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LayersService } from '@core/services/layers.service';

@Component({
    selector: 'map-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.less'],
})
export class MapComponent {
    destroyRef = inject(DestroyRef);

    popupStyle = {
        top: '50px',
        left: '200px',
    };

    isLoading = false;

    constructor(private layersService: LayersService) {
        this.layersService.isLoadingFields
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((isLoading: boolean) => (this.isLoading = isLoading));
    }
}
