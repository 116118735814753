import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LayersService } from '@core/services/layers.service';
import { EnrollmentService } from '@modules/enrollment/services/enrollment.service';
import CropType from '@shared/models/crop-type';
import Field from '@shared/models/field';
import { removeDiacritics } from '@shared/utils/utils';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

const PAGE_SIZE = 18;

interface SearchedField extends Field {
    isSelected?: boolean;
}

@Component({
    selector: 'map-field-search-list',
    templateUrl: './field-search-list.component.html',
    styleUrls: ['./field-search-list.component.less'],
})
export class FieldSearchListComponent implements OnInit {
    destroyRef = inject(DestroyRef);

    @Input()
    onFieldClick: (field: Field) => void;

    @Input()
    set enableCompactRendering(value: Boolean) {
        this.isCompact = value.toString() !== 'false';
    }

    @Input()
    set constrainHeight(value: Boolean) {
        this.isHeightConstrained = value.toString() !== 'false';
    }

    @Input()
    set useEnrollmentFields(value: Boolean) {
        this.isUsingEnrollmentFields = value.toString() !== 'false';
    }

    /**
     * The actual fields that are visible to the user
     */
    fields: SearchedField[];

    /**
     * The filtered fields. Not all the filtered fields may be visible due to pagination
     */
    filteredFields: SearchedField[];

    /**
     * All the ordered fields
     */
    allFields: SearchedField[];

    searchValue: string | null = null;
    onTextChanged = _.debounce((event) => this.filterFields(event), 250);

    pagesLoaded = 0;

    isCompact = false;
    isHeightConstrained = false;

    mapSize: string;
    mapStyle: any;

    @ViewChild('input') inputElement: ElementRef;

    private isUsingEnrollmentFields = false;

    constructor(
        private layersService: LayersService,
        private enrollmentService: EnrollmentService,
    ) {}

    ngOnInit() {
        this.mapSize = this.isCompact ? '50px' : '58px';

        this.mapStyle = {
            width: this.mapSize,
            height: this.mapSize,
            'border-radius': '2px',
            background: '#f2f2f2',
        };

        const fieldsBehaviourSubject: BehaviorSubject<Field[]> = this.isUsingEnrollmentFields
            ? this.enrollmentService.Fields
            : this.layersService.Fields;

        fieldsBehaviourSubject
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((fields: Field[]) => {
                const searchedFields: SearchedField[] = this.sanitizeFields(fields);

                this.allFields = _.orderBy(
                    searchedFields,
                    ['crop_type.name', 'name'],
                    ['asc', 'asc'],
                );

                this.filterFields(this.searchValue);
            });

        setTimeout(() => this.inputElement.nativeElement.focus(), 100);
    }

    public loadPage = (): void => {
        this.pagesLoaded += 1;
        this.fields = this.filteredFields.slice(0, this.pagesLoaded * PAGE_SIZE);
    };

    filterFields = (value: string): void => {
        this.pagesLoaded = 0;

        if (!value) {
            this.filteredFields = this.allFields.slice();
        } else {
            const sanitizedValue: string = removeDiacritics(value);
            this.filteredFields = _.filter(this.allFields, (field: SearchedField) => {
                return (
                    removeDiacritics(field.name).includes(sanitizedValue) ||
                    removeDiacritics(field.crop_type.name).includes(sanitizedValue)
                );
            });
        }

        this.loadPage();
    };

    onClick = (searchedField: SearchedField): void => {
        this.allFields = _.forEach(this.allFields, (field: SearchedField) => {
            field.isSelected = field.id === searchedField.id;
        });

        this.filteredFields = _.forEach(this.filteredFields, (field: SearchedField) => {
            field.isSelected = field.id === searchedField.id;
        });

        this.fields = _.forEach(this.fields, (field: SearchedField) => {
            field.isSelected = field.id === searchedField.id;
        });

        if (this.onFieldClick) this.onFieldClick(searchedField);
    };

    private sanitizeFields = (fields: Field[]): Field[] =>
        _.map(fields, (field: SearchedField) => ({
            ...field,
            crop_type:
                typeof field.apia_crop_type === 'number'
                    ? this.layersService.getCropType(field.apia_crop_type)
                    : (field.apia_crop_type as CropType),
        }));
}
