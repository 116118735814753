/**
 * @module ol/events/KeyCode
 */
/**
 * @enum {number}
 * @const
 */
export default {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
};
