import { Component, Input } from '@angular/core';

@Component({
    selector: 'map-zoom-buttons',
    templateUrl: './zoom-buttons.component.html',
    styleUrls: ['./zoom-buttons.component.less']
})
export class ZoomButtonsComponent {
    @Input()
    handleZoomIn: Function;

    @Input()
    handleZoomOut: Function;

    constructor() {}

    zoomOut() {
        if (this.handleZoomOut) this.handleZoomOut();
    }

    zoomIn() {
        if (this.handleZoomIn) this.handleZoomIn();
    }
}
