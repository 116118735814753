<div class="Flex-Column" [class.Height-Constrain]="isHeightConstrained">
    <div>
        <nz-input-group [nzSuffix]="suffixTemplate" [nzPrefix]="prefixTemplate">
            <input
                [style.min-width]="isCompact ? '160px' : '274px'"
                type="text"
                nz-input
                [(ngModel)]="searchValue"
                (ngModelChange)="onTextChanged($event)"
                [placeholder]="isCompact ? 'Caută terenuri' : 'Caută după denumire sau cultură'"
                #input
            />
        </nz-input-group>

        <ng-template #prefixTemplate><i nz-icon nzType="search"></i></ng-template>

        <ng-template #suffixTemplate
            ><i
                nz-icon
                nz-tooltip
                class="ant-input-clear-icon"
                nzTheme="fill"
                nzType="close-circle"
                *ngIf="searchValue"
                (click)="searchValue = null; onTextChanged(null)"
            ></i
        ></ng-template>
    </div>

    <div class="Flex-Column">
        <div *ngFor="let field of fields">
            <div class="Flex-Row Item" (click)="onClick(field)" [class.Selected]="field.isSelected">
                <map-mini-map-view
                    [inputField]="field"
                    [containerStyle]="mapStyle"
                    [cropType]="field.crop_type"
                    enableLoadingScreen="true"
                    padding="small"
                    hideBingMapsLayer
                ></map-mini-map-view>

                <div
                    class="Flex-Column Field-Info"
                    [style.padding]="isCompact ? '0 0.5rem' : '0 0.75rem'"
                >
                    <div class="Field-Name">{{ field?.name }}</div>
                    <div
                        class="Crop-Type"
                        [style.color]="field?.crop_type?.field_color"
                        [style.line-height]="isCompact ? '16px' : '18px'"
                    >
                        {{ field?.crop_type?.name }}
                    </div>
                    <div
                        nz-tooltip
                        nzTooltipPlacement="topLeft"
                        [nzTooltipTitle]="!field?.declared_area ? 'Suprafața calculată' : null"
                        class="Field-Area"
                    >
                        <span *ngIf="!field?.declared_area">*</span>
                        <span
                            >{{
                                field?.declared_area || field?.computed_area | number: '1.0-2'
                            }}
                            ha</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
