import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapRoutingModule } from './map-routing.module';
import { MapComponent } from './components/map/map.component';
import { MapPopupComponent } from './components/map-popup/map-popup.component';
import { CenterButtonsComponent } from './components/center-buttons/center-buttons.component';
import { ZoomButtonsComponent } from './components/zoom-buttons/zoom-buttons.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { MapViewComponent } from './components/map-view/map-view.component';
import { MiniMapViewComponent } from './components/mini-map-view/mini-map-view.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { LayerTypeSelectorComponent } from './components/layer-type-selector/layer-type-selector.component';
import { FieldViewComponent } from './components/field-view/field-view.component';
import { TimeSliderComponent } from './components/time-slider/time-slider.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ComparingViewComponent } from './components/comparing-view/comparing-view.component';
import { IsolationPanelComponent } from './components/isolation-panel/isolation-panel.component';
import { FieldSearchListComponent } from './components/field-search-list/field-search-list.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { OverviewComponent } from './components/overview/overview.component';
import { OverviewPopupComponent } from './components/overview-popup/overview-popup.component';
import { OverviewMapViewComponent } from './components/overview-map-view/overview-map-view.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
    declarations: [
        MapComponent,
        MapPopupComponent,
        CenterButtonsComponent,
        ZoomButtonsComponent,
        MapViewComponent,
        MiniMapViewComponent,
        SearchBarComponent,
        LayerTypeSelectorComponent,
        FieldViewComponent,
        TimeSliderComponent,
        ComparingViewComponent,
        IsolationPanelComponent,
        FieldSearchListComponent,
        OverviewComponent,
        OverviewPopupComponent,
        OverviewMapViewComponent,
    ],
    imports: [
        CommonModule,
        MapRoutingModule,
        FormsModule,
        SharedModule,
        InlineSVGModule.forRoot(),
        NzButtonModule,
        NzIconModule,
        NzSelectModule,
        NzSelectModule,
        NzModalModule,
        NzAlertModule,
        NzDropDownModule,
        NzInputModule,
        NzPopoverModule,
        NzDatePickerModule,
        NzToolTipModule,
    ],
    exports: [
        MapComponent,
        MapPopupComponent,
        CenterButtonsComponent,
        ZoomButtonsComponent,
        MiniMapViewComponent,
        FieldSearchListComponent,
    ],
})
export class MapModule {}
