import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import _ from 'lodash';
import { Router } from '@angular/router';
import { FieldInfo } from '@shared/models/field-info';
import olGeomLineString from 'ol/geom/LineString';
import olGeometry from 'ol/geom/Geometry';
import olPolygon from 'ol/geom/Polygon';
import { Operation } from '@shared/models/enrollment/editing-operation';
import Field from '@shared/models/field';
import { LayersService } from '@core/services/layers.service';
import { MapService } from '@core/services/map.service';
import { environment } from '@environments/environment';
import { AreaTag } from '@shared/models/enrollment/tags';
import { InteractiveMode } from '@shared/models/enrollment/interactive-mode';
import { ClientsService } from '@modules/clients/services/clients.service';
import { Extent } from 'ol/extent';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
    providedIn: 'root',
})
export class EnrollmentService {
    /**
     * Value incremented when it's possible to display saving progress to the user
     */
    saveProgressPercentage: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    /**
     * The current ongoing Operation
     */
    currentOperation: BehaviorSubject<Operation> = new BehaviorSubject<Operation>(null);

    /**
     * A subject which holds an arbitrary value, used to signal subscribers that
     * all operations must be cancelled when a new Observable value is emitted
     */
    cancelEverything: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * The current fields set displayed and edited in the InteractiveMapComponent
     */
    Fields: BehaviorSubject<Field[]> = new BehaviorSubject<Field[]>([]);

    /**
     * Object of type { fieldId: { property: value } }
     */
    propertyUpdates = {};

    /**
     * The final list of fields to be deleted by the FieldDeleter component when
     * the user confirms the editing/enrollment process.
     */
    fieldsToDelete: Set<number> = new Set([]);

    /**
     * The current fields marked for deletion in the current ongoing delete operation.
     * This list should be used only for user feedback. When the user confirms them,
     * they will be moved to the final list of fields to be deleted
     */
    fieldsMarkedForDeletion: BehaviorSubject<FieldInfo[]> = new BehaviorSubject<FieldInfo[]>([]);

    /**
     * The current fields marked for splitting in the current ongoing split operation.
     * This list is used for user feedback and by the split operation to determine
     * which fields will be considered when splitting.
     */
    fieldsMarkedForSplitting: BehaviorSubject<FieldInfo[]> = new BehaviorSubject<FieldInfo[]>([]);

    /**
     * The current fields marked for merging in the current ongoing merge operation.
     * This list is used for user feedback and by the merge operation to determine
     * which fields will be considered when merging.
     */
    fieldsMarkedForMerging: BehaviorSubject<FieldInfo[]> = new BehaviorSubject<FieldInfo[]>([]);

    /**
     * The current field selected for shape editing in the current ongoing field shape editing operation.
     */
    fieldMarkedForShapeEditing: BehaviorSubject<FieldInfo> = new BehaviorSubject<FieldInfo>(null);

    /**
     * The current field selected for renaming the current ongoing field rename editing operation.
     */
    fieldMarkedForRenaming: BehaviorSubject<FieldInfo> = new BehaviorSubject<FieldInfo>(null);

    /**
     * An array of Field objects which will be created when
     * the user confirms the editing/enrollment process.
     */
    fieldsToCreate: any[] = [];

    /**
     * An array of FieldInfo objects which represent fields marked for renaming.
     */
    fieldsToRename: FieldInfo[] = [];

    /**
     * The id of the field on which the InteractiveMap should be centered on when the user requests so
     */
    focusedFieldId: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    /**
     * The current internal id counter, used to assign ids to newly created fields which are not yet saved in the db
     */
    private currentId = 1;

    /**
     * The agriculture year found in the uploaded shapefile
     */
    private agricultureYear: string;

    private isLoggingEnabled = false; // !environment.production;

    constructor(
        private layersService: LayersService,
        private mapService: MapService,
        private router: Router,

        private modalService: NzModalService,
        private clientsService: ClientsService
    ) {}

    /**
     * Clears all the working variables
     */
    clean(): void {
        if (this.isLoggingEnabled) console.warn('[Enrollment-Service] clean()');
        this.saveProgressPercentage.next(0);
        this.currentOperation.next(null);
        this.Fields.next([]);

        this.propertyUpdates = {};
        this.fieldsToDelete = new Set([]);
        this.fieldsMarkedForDeletion.next([]);
        this.fieldsMarkedForSplitting.next([]);
        this.fieldsMarkedForMerging.next([]);

        this.fieldMarkedForShapeEditing.next(null);
        this.fieldMarkedForRenaming.next(null);

        this.fieldsToCreate = [];
        this.fieldsToRename = [];

        this.agricultureYear = null;
        this.currentId = 1;
    }

    /**
     * Retrieves a unique id to be assigned to a Field and increments the id counter
     */
    get Id(): number {
        return ++this.currentId;
    }

    /**
     * Triggers the cancelling methods of all operations subscribed to the 'cancelEverything' subject
     */
    cancelOngoingOperations(): void {
        this.cancelEverything.next(true);
    }

    /**
     * Method used to initialize the Fields variable and check/assign ids
     * @param fields Array of Field objects
     */
    initializeFields(fields: Field[]): void {
        this.currentId = 1;
        this.saveProgressPercentage.next(0);
        this.agricultureYear = null;

        fields.forEach((field) => {
            if (!field.id) {
                field['id'] = this.currentId++;
            } else if (field.id > this.currentId) {
                this.currentId = field.id;
            }

            // If we are initializing fields from a shapefile
            if (field.data) {
                if (field.data.BLOC_NR && field.data.PARCEL_NR) {
                    const cropNr: string = field.data.CROP_NR || '';
                    field.name = `BF${field.data.BLOC_NR} parcela ${field.data.PARCEL_NR}${cropNr}`;
                }

                if (field.data.YEAR && !this.agricultureYear) {
                    this.agricultureYear = field.data.YEAR;
                }

                field.siruta = field.data.SIRUTA;
                field.county = field.data.JUDET;
                field.commune = field.data.COMMUNE;
            }

            if (!field.name) {
                field.name = field.id.toString();
            }
        });

        if (this.isLoggingEnabled) console.log('[Enrollment-Service] initializeFields', fields);

        this.Fields.next(fields);
    }

    /**
     * Saves the current fields for the current client
     */
    saveFields(): Observable<any> {
        let fields = this.Fields.getValue();

        if (this.isLoggingEnabled)
            console.log('[Enrollment-Service] saveFields()', this.Fields.getValue());

        // Convert array coordinates to WKT strings
        fields = _.map(fields, (field: Field) => {
            if (typeof field.geometry !== 'string') {
                const wkt = `SRID=4326;${this.layersService.convertPolygonCoordinatesArrayToWkt(
                    field.geometry
                )}`;
                return _.assign(field, { geometry: wkt });
            }
            return field;
        });

        fields = _.map(fields, (field: Field) => {
            return _.assign(field, {
                name: field.name || field.id.toString(),
            });
        });

        /**
         * Creates the fields and calls getFields() afterwards
         */
        return this.layersService.createFields(fields, true).pipe(
            switchMap(() => {
                this.saveProgressPercentage.next(50);

                const clientUpdateProps: any = {
                    last_uploaded_at: new Date().toISOString(),
                };

                if (this.agricultureYear) {
                    clientUpdateProps.agriculture_year = this.agricultureYear;
                }

                const requests: any[] = [
                    this.layersService.getFields(),
                    this.clientsService.updateClient(clientUpdateProps),
                ];
                return forkJoin(requests);
            }),
            switchMap(() => this.clientsService.getClients()),
            tap(() => this.saveProgressPercentage.next(100))
        );
    }

    /**
     * Update the current fields
     */
    updateFields(): Observable<any> {
        if (this.isLoggingEnabled)
            console.log('[Enrollment-Service] updateFields()', this.Fields.getValue());

        // Each array of requests contains at least one Observable, so that
        // chained requests can subscribe to something valid
        const createFieldsRequests = [of(true)],
            propertyUpdatesRequests = [of(true)],
            deleteFieldsRequests = [of(true)];

        // Remove deleted fields from the fieldsToCreate array, as they should't be created
        this.fieldsToCreate = _.filter(this.fieldsToCreate, (field: Field) => {
            if (this.fieldsToDelete.has(field.id)) {
                this.fieldsToDelete.delete(field.id);
                if (this.isLoggingEnabled)
                    console.warn('[Enrollment-Service] Omitting field for creation', field);
                return false;
            }

            return true;
        });

        // For each field marked for renaming, if an update is already planned just add the name property to it
        this.fieldsToRename.forEach((updatedFieldInfo) => {
            const fieldId: number = updatedFieldInfo.fieldId;

            if (_.isEmpty(this.propertyUpdates[fieldId])) {
                this.propertyUpdates[fieldId] = {};
            }

            this.propertyUpdates[fieldId]['name'] = updatedFieldInfo.fieldName;
        });

        // Updates on Fields that do not exist in the db yet should be applied directly
        this.fieldsToCreate.forEach((field: Field) => {
            const fieldUpdates = this.propertyUpdates[field.id];

            if (!_.isEmpty(fieldUpdates)) {
                _.forOwn(fieldUpdates, (value, property) => {
                    if (this.isLoggingEnabled)
                        console.log(
                            '[Enrollment-Service] Updating the following field directly',
                            field.name,
                            property,
                            value
                        );
                    field[property] = value;
                    delete fieldUpdates[property];
                });
            }
        });

        // Sanitize the Fields array: convert the geometry property to WKT string
        this.fieldsToCreate = _.map(this.fieldsToCreate, (field: Field) => {
            if (typeof field.geometry !== 'string') {
                const wkt = `SRID=4326;${this.layersService.convertPolygonCoordinatesArrayToWkt(
                    field.geometry
                )}`;
                return _.assign(field, { geometry: wkt });
            }

            return field;
        });

        if (this.isLoggingEnabled)
            console.log('[Enrollment-Service] Creating fields', this.fieldsToCreate);

        if (!_.isEmpty(this.fieldsToCreate)) {
            createFieldsRequests.push(this.layersService.createFields(this.fieldsToCreate));
        }

        // Create update calls for the property updates
        _.forOwn(this.propertyUpdates, (value, key) => {
            const fieldId: number = Number.parseInt(key);
            const searchedField = this.Fields.getValue().find((field) => field.id === fieldId);

            // If the searched field does not exist in the Fields array, it means it's either
            // planned for deleting or was omitted in the creation process
            if (searchedField && !_.isEmpty(value)) {
                if (this.isLoggingEnabled)
                    console.log(`[Enrollment-Service] Updating field ${fieldId}`, value);

                propertyUpdatesRequests.push(this.layersService.updateField(fieldId, value));
            }
        });

        this.fieldsToDelete.forEach((fieldId: number) => {
            if (this.isLoggingEnabled)
                console.log(`[Enrollment-Service] Deleting field ${fieldId}`);

            deleteFieldsRequests.push(this.layersService.deleteField(fieldId));
        });

        return forkJoin(createFieldsRequests).pipe(
            switchMap((createFieldsResponse) => {
                if (this.isLoggingEnabled)
                    console.log(
                        '[Enrollment-Service] #1 createFieldsResponse',
                        createFieldsResponse
                    );

                this.saveProgressPercentage.next(25);
                return forkJoin(propertyUpdatesRequests);
            }),
            switchMap((propertyUpdatesResponse) => {
                if (this.isLoggingEnabled)
                    console.log(
                        '[Enrollment-Service] #2 propertyUpdatesResponse',
                        propertyUpdatesResponse
                    );

                this.saveProgressPercentage.next(50);
                return forkJoin(deleteFieldsRequests);
            }),
            switchMap((deleteFieldsResponse) => {
                if (this.isLoggingEnabled)
                    console.log(
                        '[Enrollment-Service] $3 deleteFieldsResponse',
                        deleteFieldsResponse
                    );
                this.saveProgressPercentage.next(75);
                return this.layersService.getFields();
            }),
            tap(
                () => {
                    this.saveProgressPercentage.next(100);
                },
                (error) => {
                    console.error(error);
                }
            )
        );
    }

    /**
     * Splits the given fields with the chosen lineString, and returns an array of
     * objects containing the pole of inaccessibility and the area of each resulting polygon
     * @param geom Geometry containing a lineString segment
     * @param markedFields The fields considered for splitting
     */
    getSplittingTags(geom: olGeometry, markedFields: FieldInfo[]): AreaTag[] {
        const splittingTags: AreaTag[] = [];

        if (geom instanceof olGeomLineString) {
            const { line, bufferedLine } = this.mapService.getSplittingLine(geom);

            markedFields.forEach((fieldInfo) => {
                const field: Field = this.Fields.getValue().find(
                    (value) => value.id === fieldInfo.fieldId
                );

                if (field) {
                    const coordinates = this.layersService.getPolygonCoordinates(field.geometry);
                    const poly = this.mapService.getTurfPolygon(coordinates);

                    const isIntersecting = this.mapService.isLineFullyIntersectingPolygon(
                        poly,
                        line
                    );

                    if (isIntersecting) {
                        const diff = this.mapService.getFeaturesDifference(poly, bufferedLine);

                        _.forEach(diff.geometry.coordinates, (polygonCoordinates) => {
                            const geometry: olGeometry = this.layersService
                                .readFeature(polygonCoordinates)
                                .getGeometry();

                            const area = this.layersService.getPolygonArea(geometry);
                            const poleOfInaccessibility =
                                this.layersService.getPoleOfInaccessibility(
                                    (geometry as olPolygon).getCoordinates()
                                );

                            const tag: AreaTag = {
                                poleOfInaccessibility,
                                area,
                            };

                            splittingTags.push(tag);
                        });
                    }
                } else {
                    console.error(
                        '[Enrollment-Service] getSplittingTags: cannot find Field object'
                    );
                }
            });
        }

        return splittingTags;
    }

    /**
     * Displays a modal which asks the user to confirm that they
     * wish to exit the enrollment/editing process early
     */
    confirmExit(mode: InteractiveMode = InteractiveMode.Uploading): void {
        const title = `Ieși din procesul de ${
            mode === InteractiveMode.Uploading ? 'încărcare' : 'editare'
        }?`;
        this.modalService.confirm({
            nzTitle: title,
            nzOkText: 'Ieși',
            nzOkDanger: true,
            nzOnOk: () => {
                this.router.navigateByUrl('/clienti');
            },
            nzCancelText: 'Rămâi',
        });
    }
}
