<div class="Map-View-Wrapper">
    <div id="Map-View-Container-{{ mapId }}" class="Map-View-Container"></div>
    <span class="Copernicus">Contains modified Copernicus Sentinel data</span>
    <map-popup [popupInfo]="fieldPopupInfo" [containerStyle]="popupStyle"></map-popup>

    <shared-loading-screen
        *ngIf="isLoadingScreenVisible"
        [@loadingAnimation]
    ></shared-loading-screen>
</div>
