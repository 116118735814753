import { Location } from '@angular/common';
import { Component, DestroyRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { LayersService } from '@app/core/services/layers.service';
import { PermissionsService } from '@core/services/permissions.service';
import { WeatherService } from '@core/services/weather.service';
import { environment } from '@environments/environment';
import CropType from '@shared/models/crop-type';
import Field from '@shared/models/field';
import { LayerItem, LayerType } from '@shared/models/map/layer';
import { NdviData } from '@shared/models/map/ndvi';
import { addMonths, setHours, setMilliseconds, setMinutes, setSeconds, subMonths } from 'date-fns';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MapViewComponent } from '../map-view/map-view.component';
import { TimeSliderComponent } from '../time-slider/time-slider.component';

@Component({
    selector: 'map-field-view',
    templateUrl: './field-view.component.html',
    styleUrls: ['./field-view.component.less'],
})
export class FieldViewComponent implements OnInit, OnDestroy {
    destroyRef = inject(DestroyRef);

    field: Field;
    cropType: CropType;

    isReadyToInitialize = false;
    isIsolationModeActive = false;

    timesliderStyle: any;
    ndviComponentStyle: any;

    initialLayerType: LayerType = null;

    visibleLayerTypes: LayerType[] = [];

    currentTimesliderEntry: NdviData;
    initialTimesliderDate: string;

    dateRange: Date[] = [];

    weatherData: any;

    /**
     * Permissions
     */
    permissions = [];

    private isLoggingEnabled = !environment.production;

    @ViewChild('timeslider')
    private timeslider: TimeSliderComponent;

    @ViewChild('mapView')
    private mapView: MapViewComponent;

    constructor(
        private layersService: LayersService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private weatherService: WeatherService,
        private permissionsService: PermissionsService,
        private location: Location,
    ) {
        // This option is used to reload the page when routing to a different Field
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnDestroy() {}

    ngOnInit() {
        // Obtain permissions
        this.permissions = this.permissionsService.Permissions;

        this.layersService.currentLayerItem
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((layerItem: LayerItem) => {
                // Hide/show timeslider/legends
                if (layerItem && this.layersService.isNdviLayerType(layerItem.layerType)) {
                    this.timesliderStyle = {
                        opacity: '1',
                        'pointer-events': 'all',
                    };
                } else {
                    this.timesliderStyle = {
                        opacity: '0',
                        'pointer-events': 'none',
                    };
                }

                if (layerItem && layerItem.layerType === LayerType.Outline) {
                    this.ndviComponentStyle = {
                        display: 'none',
                    };
                } else {
                    this.ndviComponentStyle = {
                        display: 'inline-flex',
                    };
                }
            });

        this.activatedRoute.params
            .pipe(
                take(1),
                switchMap((params) => {
                    const id = Number.parseInt(params.fieldId);
                    this.initialLayerType = params.layerType;
                    this.initialTimesliderDate = params.initialTimesliderDate;

                    // If an initial timeslider date was provided, the date range is set around it
                    if (this.initialTimesliderDate) {
                        const initialDate: Date = new Date(this.initialTimesliderDate);
                        const leftDate: Date = subMonths(initialDate, 2);
                        const rightDate: Date = addMonths(initialDate, 2);

                        this.dateRange = [leftDate, rightDate];
                    } else {
                        this.dateRange = [subMonths(new Date(), 6), new Date()];
                    }

                    if (!id) {
                        throw new Error('Invalid fieldId');
                    }

                    const field: Field = this.layersService.getField(id);

                    if (!field) {
                        throw new Error('Field does not exist');
                    }

                    return of(field);
                }),
            )
            .subscribe(
                (field: Field) => {
                    this.visibleLayerTypes = [LayerType.Outline, LayerType.Vegetatie];

                    this.field = field;
                    this.cropType = this.layersService.getCropType(field.apia_crop_type);

                    // if (this.isLoggingEnabled) console.log('[Field-View]', this.field);
                    this.isReadyToInitialize = true;
                },
                (error) => {
                    console.log(error.message);
                    this.route();
                },
            );
    }

    public getDateRange = (): Date[] => this.dateRange;

    public onSearchedFieldClick = (field: Field): void => {
        console.log('onSearchedFieldClick');

        this.router.navigateByUrl(
            `/harti/${field.id}/vizualizare/${
                this.layersService.currentLayerItem.getValue().layerType
            }`,
        );
    };

    route = (): any => this.router.navigateByUrl('/harti');

    goToComparingView = (): void => {
        const currentTimesliderEntry: NdviData = this.timeslider.currentTimesliderEntry;
        this.router.navigateByUrl(
            `/harti/${this.field.id}/comparatie/${
                currentTimesliderEntry ? currentTimesliderEntry.date : ''
            }`,
        );
    };

    onDateChange(range: Date[]): void {
        // Set time on dates (00:00 and 23:59)
        const startDate = setHours(setMinutes(setSeconds(setMilliseconds(range[0], 0), 0), 0), 0);
        const endDate = setHours(
            setMinutes(setSeconds(setMilliseconds(range[1], 999), 59), 59),
            23,
        );

        this.timeslider.setTimesliderInterval([startDate, endDate]);
    }

    setCurrentTimesliderDate = (entry: NdviData): any => {
        this.currentTimesliderEntry = entry;

        if (entry) {
            this.weatherService
                .getHistoricalWeatherData(this.field.id, entry.date)
                .pipe(take(1))
                .subscribe((weatherData: any) => {
                    this.weatherData = weatherData;
                });
        }
    };

    toggleIsolationMode = (value: boolean): void => {
        this.isIsolationModeActive = value;
    };

    intersectIsolationPolygonWithField = (): void => {
        this.mapView.intersectDrawnFeatureWithPolygon(
            this.layersService.getPolygonCoordinates(this.field.geometry),
        );
    };
}
