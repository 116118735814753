import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MapService } from '@core/services/map.service';
import { OverviewService } from '@modules/map/services/overview.service';
import { Client, ClientSet } from '@shared/models/client';
import { CurrentYearData } from '@shared/models/overview';
import _ from 'lodash';
import olFeature from 'ol/Feature';
import olMap from 'ol/Map';
import olMapBrowserEvent from 'ol/MapBrowserEvent';
import olView from 'ol/View';
import { Extent } from 'ol/extent';
import DragPan from 'ol/interaction/DragPan';
import DragRotate from 'ol/interaction/DragRotate';
import DragRotateAndZoom from 'ol/interaction/DragRotateAndZoom';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import PinchRotate from 'ol/interaction/PinchRotate';
import PinchZoom from 'ol/interaction/PinchZoom';
import Pointer from 'ol/interaction/Pointer';
import olLayerTile from 'ol/layer/Tile';
import { pairwise, startWith } from 'rxjs/operators';

@Component({
    selector: 'map-overview-map-view',
    templateUrl: './overview-map-view.component.html',
    styleUrls: ['./overview-map-view.component.less'],
    animations: [
        trigger('loadingAnimation', [
            transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class OverviewMapViewComponent implements OnInit, AfterViewInit {
    destroyRef = inject(DestroyRef);

    view: olView;
    map: olMap;
    bingMapsLayer: olLayerTile;

    clients: Client[];
    clientSet: ClientSet;

    isLoadingScreenVisible = true;

    /**
     * The feature which is currently hovered by the user
     */
    private highlightedFeature: olFeature;

    /**
     * Romania
     */
    private boundingBox: Extent = [20.2201924985, 43.6884447292, 29.62654341, 48.2208812526];

    constructor(
        private mapService: MapService,
        private overviewService: OverviewService,
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        const viewOptions = {
            center: [28.0537149880826, 45.2379014113663] as [number, number],
            zoom: 10,
            minZoom: 7,
            maxZoom: 19,
            projection: 'EPSG:4326',
        };

        this.view = new olView(viewOptions);
        this.bingMapsLayer = this.mapService.getBingMapsLayer('CanvasGray');

        this.map = new olMap({
            target: 'Overview-Map-Container',
            view: this.view,
            layers: [this.bingMapsLayer],
            overlays: [],
            controls: [],
            interactions: [
                new DragPan(),
                new MouseWheelZoom(),
                new DragRotate(),
                new DragRotateAndZoom(),
                new Pointer(),
                new PinchZoom(),
                new PinchRotate(),
            ],
        });

        this.fitBoundingBox(0);

        setTimeout(() => {
            this.map.updateSize();
            this.map.changed();
            this.isLoadingScreenVisible = false;
        }, 0);

        setTimeout(() => {
            this.overviewService.CurrentYear.pipe(
                startWith({} as CurrentYearData),
                pairwise(),
                takeUntilDestroyed(this.destroyRef),
            ).subscribe(([previousValue, currentValue]) => {
                if (
                    _.isEmpty(currentValue.ClientSets) ||
                    previousValue.year !== currentValue.year
                ) {
                    const layers = this.map.getLayers().getArray();
                    for (let i = layers.length - 1; i >= 0; i = i - 1) {
                        if (layers[i] !== this.bingMapsLayer) {
                            this.map.removeLayer(layers[i]);
                        }
                    }
                }

                const newSets = _.differenceBy(
                    currentValue.ClientSets,
                    previousValue.ClientSets,
                    'client_id',
                );

                const ar = this.mapService.createClientSetsLayers(newSets);
                _.forEach(ar, (layer) => this.map.addLayer(layer));
            });
        }, 0);

        // Hovering event
        this.map.on('pointermove', (evt: olMapBrowserEvent) => {
            const featureLike = this.map.forEachFeatureAtPixel(evt.pixel, (f) => f);
            this.handleHovering(featureLike as olFeature);
        });
    }

    /**
     * Zooms in the main view by 1 zoom level
     */
    public zoomIn = () => {
        this.view.animate({ zoom: this.view.getZoom() + 1, duration: 250 });
    };

    /**
     * Zooms out the main view by 1 zoom level
     */
    public zoomOut = () => {
        this.view.animate({ zoom: this.view.getZoom() - 1, duration: 250 });
    };

    /**
     * Fits the bounding box of the visible layers
     * @param duration Animation duration
     */
    public fitBoundingBox = (duration: number = 600) => {
        if (!this.boundingBox.includes(Infinity)) {
            const padding = 40;

            const options = {
                duration,
                constrainResolution: false,
                padding: [padding, padding, padding, padding],
                maxZoom: 18,
            };

            this.view.fit(this.boundingBox, options);
        } else {
            console.error('[Overview-Map-View] Invalid bounding box', this.boundingBox);
        }
    };

    private handleHovering(feature: olFeature): void {
        if (feature !== this.highlightedFeature) {
            if (this.highlightedFeature) {
                this.highlightedFeature.set('hover', false);
            }

            if (feature) {
                this.clientSet = feature.get('clientSet');

                this.setCursor('pointer');
                feature.set('hover', true);
            } else {
                this.clientSet = null;
                this.setCursor('auto');
            }

            this.highlightedFeature = feature;
        }
    }

    private setCursor(value: string): void {
        document.getElementById(this.map.getTarget() as string).style.cursor = value;
    }
}
