import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WeatherService {
    constructor(private apiService: ApiService) {}

    getHistoricalWeatherData(fieldId: number, date: string): Observable<any> {
        // const isoDate: string = format(date, 'YYYY-MM-DD');
        return this.apiService.getHistoricalWeatherData(fieldId, date);
    }
}
