<div class="Map-View-Wrapper">
    <div id="Overview-Map-Container" class="Map-View-Container"></div>
    <span class="Copernicus">Contains modified Copernicus Sentinel data</span>
    <map-overview-popup [clientSet]="clientSet"></map-overview-popup>

    <shared-loading-screen
        *ngIf="isLoadingScreenVisible"
        [@loadingAnimation]
    ></shared-loading-screen>
</div>
