import { Injectable } from '@angular/core';
import { LayersService } from './layers.service';
import polylabel from 'polylabel';
import olLayerImage from 'ol/layer/Image';
import olCollection from 'ol/Collection';
import olLayerGroup from 'ol/layer/Group';
import olSourceVector from 'ol/source/Vector';
import olSourceRaster from 'ol/source/Raster';
import olSourceCluster from 'ol/source/Cluster';
import olSourceXYZ from 'ol/source/XYZ';
import olImageTile from 'ol/ImageTile';
import olLayerTile from 'ol/layer/Tile';
import olSourceBingMaps from 'ol/source/BingMaps';
import olBaseLayer from 'ol/layer/Base';
import { Coordinate as olCoordinate } from 'ol/coordinate';
import olPolygon from 'ol/geom/Polygon';
import olGeomPoint from 'ol/geom/Point';
import olGeometry from 'ol/geom/Geometry';
import olFeature from 'ol/Feature';
import olGeomLineString from 'ol/geom/LineString';
import olVectorImage from 'ol/layer/VectorImage';
import { Polygon, Units } from '@turf/turf';
import getRainbowVector from '@shared/utils/rainbow';
import Field from '@shared/models/field';
import { LayerType } from '@shared/models/map/layer';
import { LengthTag } from '@shared/models/enrollment/tags';
import { FieldInfo } from '@shared/models/field-info';
import CropType from '@shared/models/crop-type';
import { NdviData } from '@shared/models/map/ndvi';
import * as turf from '@turf/turf';
import _ from 'lodash';

import {
    outlineStyle,
    getFieldDescriptorStyle,
    getPinFeatureStyle,
    simpleStyle,
} from '@shared/utils/styles';
import { ClientSet } from '@shared/models/client';

const rainbows = {
    Vegetatie: getRainbowVector(['red', 'yellow', 'green']),
    FaRMN: getRainbowVector(['orangered', 'dodgerblue', 'lightskyblue', 'white']),
};

const REFRESH_DELAY = 4000;

@Injectable({
    providedIn: 'root',
})
export class MapService {
    private bingMapsLayer: olLayerTile;
    private isLoggingEnabled = false;

    constructor(private layersService: LayersService) {}

    /**
     * Creates a signle vector layer containing a single transparent outline feature
     * @param field The field object
     */
    createSingleOutlineLayer(field: Field): olVectorImage {
        const fieldInfo: FieldInfo = {
            fieldName: 'Field',
            cropType: this.layersService.getCropType(1),
            fieldArea: field.declared_area ? field.declared_area : field.computed_area,
            fieldId: field.id,
            isAreaComputed: !field.declared_area,
        };

        const outlineFeature: olFeature = this.layersService.readFeature(field.geometry);
        outlineFeature.set('fieldInfo', fieldInfo);
        outlineFeature.set('vegetation', true); // Used for styling

        return new olVectorImage({
            source: new olSourceVector({
                features: new olCollection<olFeature>([outlineFeature]),
            }),
            zIndex: 20,
            style: outlineStyle,
        });
    }

    /**
     * Creates a single image layer containing one NDVI source for a field
     * @param field The field object
     * @param timesliderEntry The timelideryEntry
     * @param zIndex The zIndex of the layer
     */
    createSingleVegetationLayer(
        field: Field,
        timesliderEntry: NdviData,
        zIndex: number
    ): olLayerImage {
        const geometry: olGeometry = this.layersService.readFeature(field.geometry).getGeometry();

        const imageLayer = new olLayerImage({
            source: null, // Source is passed on later
            extent: (geometry as olPolygon).getExtent(),
            zIndex,
            visible: false,
        });

        if (timesliderEntry) {
            this.setRasterSource(imageLayer, timesliderEntry);
        }

        imageLayer.setVisible(true);

        return imageLayer;
    }

    /**
     * Applies the dissolve operation on an array of polygons. The polygons which are overlapping
     * will be unified. If only one polygon results after the unification, its geometry is returned.
     * Otherwise, if the polygons are not overlapping, an empty array is returned.
     * @param fields Array of fields
     */
    dissolvePolygons(polygons: olPolygon[]): [number, number][][] {
        const turfPolygonsArray = [];

        polygons.forEach((olPoly) => {
            turfPolygonsArray.push(turf.polygon(olPoly.getCoordinates(), { combine: 'yes' }));
        });

        const features = turf.featureCollection<Polygon>(turfPolygonsArray);
        const result = turf.dissolve(features, { propertyName: 'combine' });

        return result.features.length === 1
            ? (result.features[0].geometry.coordinates as [number, number][][])
            : [];
    }

    /**
     * Extracts a line from the given feature and returns a Turf LineString and a
     * buffered line (the same line with a slightly larger width)
     * @param geom A geometry containing a lineString
     */
    getSplittingLine(geom: olGeometry): { line: any; bufferedLine: any } {
        const lineStr: olGeomLineString = geom as olGeomLineString;
        const line = turf.lineString([lineStr.getFirstCoordinate(), lineStr.getLastCoordinate()]);

        const scaledLine = turf.transformScale(line, 2);

        const bufferedLine = turf.buffer(scaledLine, 0.0001, { units: 'miles', steps: 1 });

        return {
            line: scaledLine,
            bufferedLine,
        };
    }

    /**
     * Offsets the given lineString and returns the coordinates of the new line
     * @param geom A geometry containing a lineString
     * @param offsetValue The value used to offset the lineString
     */
    getOffsetLine(geom: olGeometry, offsetValue: number, offsetUnits: string): [number, number][] {
        const lineStr: olGeomLineString = geom as olGeomLineString;
        const line = turf.lineString([lineStr.getFirstCoordinate(), lineStr.getLastCoordinate()]);

        return turf.lineOffset(line, offsetValue, { units: offsetUnits as Units }).geometry
            .coordinates as [number, number][];
    }

    /**
     * Creates a turf polygon
     * @param coordinates Polygon coordinates array
     */
    getTurfPolygon(coordinates: any): any {
        return turf.polygon(coordinates);
    }

    /**
     * Checks if the given line intersects and fully traverses the polygon,
     * and that neither point of the line resides inside the polygon
     * @param polygon The polygon used for intersection checking
     * @param line The given line
     */
    isLineFullyIntersectingPolygon(polygon: any, line: any): boolean {
        const intersection = turf.lineIntersect(line, polygon);

        const point_0 = turf.point(line.geometry.coordinates[0]);
        const point_1 = turf.point(line.geometry.coordinates[1]);

        return (
            intersection.features.length > 0 &&
            !turf.booleanPointInPolygon(point_0, polygon) &&
            !turf.booleanPointInPolygon(point_1, polygon)
        );
    }

    /**
     * Computes the turf difference between the given features
     * @param feature1 The first feature
     * @param feature2 The second feature
     */
    getFeaturesDifference(feature1: any, feature2: any): any {
        return turf.difference(feature1, feature2);
    }

    /**
     * Creates an openLayers linestring geometry
     * @param point_0 The first point coordinates
     * @param point_1 The second point coordinates
     */
    createLineStringGeometry(
        point_0: [number, number],
        point_1: [number, number]
    ): olGeomLineString {
        return new olGeomLineString([point_0, point_1]);
    }

    /**
     * Checks if the polygon intersects the given fields polygons and returns an array of
     * field names which intersect with the chosen polygon
     * @param coordinates The coordinates of the polygon used to check overlapping
     * @param fields The fields against which the intersection is checked
     */
    getPolygonIntersectionsWithSet(coordinates: olCoordinate[][], fields: Field[]): string[] {
        const poly = turf.polygon(coordinates);
        const intersections: string[] = [];

        fields.forEach((field) => {
            let iteratedPoly;

            if (typeof field.geometry === 'string') {
                iteratedPoly = turf.polygon(
                    this.layersService.getPolygonCoordinates(field.geometry)
                );
            } else {
                iteratedPoly = turf.polygon(field.geometry);
            }

            let intersection;

            try {
                intersection = turf.intersect(poly, iteratedPoly);
            } catch (error) {
                console.error(`Turf intersection error ${field.name}`, error);
            }

            if (intersection) {
                intersections.push(field.name);
            }
        });

        return intersections;
    }

    /**
     * Returns the intersection between 2 polygons
     * @param poly1 The coordinates of the first polygon
     * @param poly2 The coordinates of the second polygon
     */
    getPolygonsIntersection(poly1: olCoordinate[][], poly2: olCoordinate[][]): any {
        let intersection;

        try {
            intersection = turf.intersect(turf.polygon(poly1), turf.polygon(poly2));
        } catch (error) {
            console.error(`Turf intersection error`, error);
            return null;
        }

        return intersection && intersection.geometry.type === 'Polygon'
            ? intersection.geometry.coordinates
            : null;
    }

    /**
     * Obtains the middle points of the segments which compose the given polygon.
     * Returns an array of length tags, with each tag containing the middle point
     * of a segment and its length in meters
     * @param olPoly The polygon which will have its segment measured
     */
    getLengthTagsOfPolygonSegments(olPoly: olPolygon): LengthTag[] {
        const lengthTags: LengthTag[] = [];
        let coords: olCoordinate[] = olPoly.getCoordinates()[0];

        // If the polygon contains only 3 coord. points, the first and last one will coincide
        if (coords.length === 3) {
            coords = coords.slice(1);
        }

        for (let i = 0; i < coords.length - 1; i++) {
            const lineStringGeom: olGeomLineString = new olGeomLineString([
                coords[i],
                coords[i + 1],
            ]);
            const length = this.layersService.getLineStringLength(lineStringGeom);

            // If the length is too small, we don't add the tag so it won't clutter the view
            if (length >= 5) {
                const midPoint = turf.midpoint(turf.point(coords[i]), turf.point(coords[i + 1]));
                lengthTags.push({
                    position: midPoint.geometry.coordinates as [number, number],
                    value: Number.parseFloat(length.toFixed(1)),
                });
            }
        }

        return lengthTags;
    }

    getBingMapsLayer(imagerySet = 'AerialWithLabelsOnDemand'): olLayerTile {
        const bingMapsSource = new olSourceBingMaps({
            key: 'AvJzYJDF4KekWS-urHxG_aBrQ2dIFWyBffbrQhrimiUJOMYdQIRe9JTa7jGixEw-',
            imagerySet,
            culture: 'ro-RO',
        });

        const bingMapsLayer = new olLayerTile({
            source: bingMapsSource,
            preload: Infinity,
        });

        bingMapsLayer.set('bingMaps', true);

        return bingMapsLayer;
    }

    createOutlineLayerGroup(
        fields: Field[],
        options: {
            addFieldDescriptors?: boolean;
            addBingMaps?: boolean;
            pinFeature?: olFeature;
        }
    ): olLayerGroup {
        const { addFieldDescriptors, addBingMaps, pinFeature } = options;

        const layersArray: olBaseLayer[] = [];
        const outlineFeaturesCollection = new olCollection<olFeature>();
        const circleFeaturesCollection = new olCollection<olFeature>();

        for (const field of fields) {
            const cropType: CropType =
                typeof field.apia_crop_type === 'number'
                    ? this.layersService.getCropType(field.apia_crop_type)
                    : (field.apia_crop_type as CropType);

            if (!cropType) {
                console.error('Invalid cropType', field);
            }

            const outlineFeature: olFeature = this.layersService.readFeature(field.geometry);
            let poleOfInaccessibility = null;

            if (!field.id) {
                console.error(`Field ${field.name} has no ID!`);
            }

            if (!field.computed_area) {
                field.computed_area = this.layersService.getPolygonArea(
                    outlineFeature.getGeometry()
                );
            }

            const fieldInfo: FieldInfo = {
                fieldName: field.name || field.id,
                cropType,
                fieldArea: field.declared_area ? field.declared_area : field.computed_area,
                fieldId: field.id,
                isAreaComputed: !field.declared_area,
            };

            if (addFieldDescriptors) {
                const polygon: olPolygon = outlineFeature.getGeometry() as olPolygon;

                try {
                    poleOfInaccessibility = polylabel(polygon.getCoordinates());
                } catch (err) {
                    console.error('Cannot compute poleOfInaccessibility', field, err);
                    continue;
                }

                fieldInfo.poleOfInaccessibility = poleOfInaccessibility;

                const circleFeature = new olFeature(new olGeomPoint(poleOfInaccessibility));
                circleFeature.set('isCircle', true);
                circleFeature.set('fieldInfo', fieldInfo);
                circleFeature.setStyle(getFieldDescriptorStyle(cropType));

                circleFeaturesCollection.push(circleFeature);
            }

            outlineFeature.set('isField', true);
            outlineFeature.set('fieldInfo', fieldInfo);

            outlineFeaturesCollection.push(outlineFeature);
        }

        /**
         * Create a pin layer if the coordinates of a point were provided
         */
        if (pinFeature) {
            pinFeature.setStyle(getPinFeatureStyle());

            layersArray.push(
                new olVectorImage({
                    source: new olSourceVector({
                        features: [pinFeature],
                    }),
                    zIndex: 3,
                })
            );
        }

        const outlineFeaturesLayer = new olVectorImage({
            source: new olSourceVector({
                features: outlineFeaturesCollection,
            }),
            zIndex: 1,
            style: outlineStyle,
        });

        outlineFeaturesLayer.set(
            'fullCollection',
            new olCollection<olFeature>(outlineFeaturesCollection.getArray().slice()),
            true
        );

        outlineFeaturesLayer.set('canProvideExtent', true);
        outlineFeaturesLayer.set('isFieldsLayer', true);

        layersArray.push(outlineFeaturesLayer);

        if (addFieldDescriptors) {
            const circleFeaturesLayer = new olVectorImage({
                source: new olSourceCluster({
                    source: new olSourceVector({
                        features: circleFeaturesCollection,
                    }),
                }),
                zIndex: 2,
                style: (featureLike) => {
                    const feature: olFeature = _.head(featureLike.get('features'));
                    return feature
                        ? getFieldDescriptorStyle(feature.get('fieldInfo').cropType)
                        : null;
                },
            });

            circleFeaturesLayer.set(
                'fullCollection',
                new olCollection<olFeature>(circleFeaturesCollection.getArray().slice()),
                true
            );
            circleFeaturesLayer.set('isDescriptorsLayer', true, true);

            layersArray.push(circleFeaturesLayer);
        }

        return this.getLayerGroup(layersArray, _.size(fields), LayerType.Outline, addBingMaps);
    }

    createVegetationLayerGroup(
        fields: Field[],
        options: {
            ndviData?: { fieldId: NdviData };
            tilesURL?: string;
            addBingMaps?: boolean;
        }
    ): olLayerGroup {
        const { ndviData, tilesURL, addBingMaps } = options;

        const layersArray: olBaseLayer[] = [];
        const outlineFeaturesCollection = new olCollection<olFeature>();

        for (const field of fields) {
            const cropType: CropType = this.layersService.getCropType(field.apia_crop_type);
            const outlineFeature = this.layersService.readFeature(field.geometry);

            if (!field.computed_area) {
                field.computed_area = this.layersService.getPolygonArea(
                    outlineFeature.getGeometry()
                );
            }

            const fieldInfo: FieldInfo = {
                fieldName: field.name || field.id,
                cropType,
                fieldArea: field.declared_area ? field.declared_area : field.computed_area,
                fieldId: field.id,
                isAreaComputed: !field.declared_area,
            };

            outlineFeature.set('isField', true);
            outlineFeature.set('vegetation', true); // Used for styling
            outlineFeature.set('fieldInfo', fieldInfo);

            outlineFeaturesCollection.push(outlineFeature);

            // NDVI
            const ndviLayer = new olLayerImage({
                source: null, // Source is passed on later
                extent: (outlineFeature.getGeometry() as olPolygon).getExtent(),
                zIndex: 1,
                visible: false,
            });

            ndviLayer.set('fieldInfo', fieldInfo, true);

            if (tilesURL || (ndviData && ndviData[field.id])) {
                /**
                 * When passing the tilesURL for the XYZ source, the one provided through a timeslider entry is prioritized
                 */
                ndviLayer.setSource(
                    this.createNdviRasterSource(
                        this.createXYZSource(ndviLayer, tilesURL || ndviData[field.id].tiles_url),
                        rainbows[LayerType.Vegetatie]
                    )
                );
            } else {
                console.error('No satellite imagery available for this set');
            }

            ndviLayer.setVisible(true);

            layersArray.push(ndviLayer);
        }

        const outlineFeaturesLayer = new olVectorImage({
            source: new olSourceVector({
                features: outlineFeaturesCollection,
            }),
            zIndex: 2,
            style: outlineStyle,
        });

        outlineFeaturesLayer.set(
            'fullCollection',
            new olCollection<olFeature>(outlineFeaturesCollection.getArray().slice()),
            true
        );
        outlineFeaturesLayer.set('isFieldsLayer', true);
        outlineFeaturesLayer.set('canProvideExtent', true);

        layersArray.push(outlineFeaturesLayer);

        return this.getLayerGroup(layersArray, _.size(fields), LayerType.Vegetatie, addBingMaps);
    }

    createClientSetsLayers(clientSets: ClientSet[]): olVectorImage[] {
        const layers: olVectorImage[] = [];
        const featuresCollection = new olCollection<olFeature>();

        for (const set of clientSets) {
            const outlineFeature: olFeature = this.layersService.readFeature(set.geometry);

            outlineFeature.set('clientSet', set);
            featuresCollection.push(outlineFeature);
        }

        const featuresLayer = new olVectorImage({
            source: new olSourceVector({
                features: featuresCollection,
            }),
            zIndex: 1,
            style: simpleStyle,
        });

        featuresLayer.set('canProvideExtent', true);
        layers.push(featuresLayer);

        return layers;
    }

    /**
     * Set a new raster source for the given layer, containing NDVI imagery for the given timeslider entry
     * @param imageLayer The olLayerImage
     * @param timesliderEntry The timeslider entry which contains the date to be set
     */
    setRasterSource(
        imageLayer: olLayerImage,
        timesliderEntry: NdviData,
        shouldRefresh = true
    ): void {
        const rasterSource: olSourceRaster = this.createNdviRasterSource(
            this.createXYZSource(imageLayer, timesliderEntry.tiles_url),
            rainbows[LayerType.Vegetatie]
        );

        imageLayer.setSource(rasterSource);

        // Refresh source rendering
        if (shouldRefresh) setTimeout(() => rasterSource.changed(), REFRESH_DELAY / 2);
    }

    /**
     * Creates and returns an NDVI raster source with the provided XYZ source
     * @param source The XYZ sOURCE
     * @param rainbow The rainbow used for coloring
     */
    private createNdviRasterSource(source: olSourceXYZ, rainbow): olSourceRaster {
        const raster = new olSourceRaster({
            sources: [source],
            threads: 4,
            operationType: 'image',
            operation: (imageDataArray: ImageData[], data: any): any => {
                const imageData = imageDataArray[0];
                const pixelsArray = new Uint8ClampedArray(imageData.height * imageData.width * 4);

                for (let i = 0; i < imageData.height * imageData.width * 4; i += 4) {
                    if (imageData.data[i + 3] < 120) {
                        pixelsArray[i] =
                            pixelsArray[i + 1] =
                            pixelsArray[i + 2] =
                            pixelsArray[i + 3] =
                                0;
                        continue;
                    }

                    // Clouds
                    let colorObject = {
                        red: 200,
                        green: 200,
                        blue: 200,
                    };

                    if (imageData.data[i] < 250) {
                        const vectorIndex = imageData.data[i];
                        colorObject = data.vector[vectorIndex];
                    }

                    pixelsArray[i] = colorObject.red;
                    pixelsArray[i + 1] = colorObject.green;
                    pixelsArray[i + 2] = colorObject.blue;
                    pixelsArray[i + 3] = 255;
                }

                return new ImageData(pixelsArray, imageData.width, imageData.height);
            },
        });

        raster.on('beforeoperations', (event) => {
            event['data'].vector = rainbow;
        });

        return raster;
    }

    private getLayerGroup = (
        layers: olBaseLayer[],
        fieldsCount: number,
        layerType: LayerType,
        addBingMaps: boolean
    ): olLayerGroup => {
        /**
         * A main layer is a layer which is not bing maps
         */
        const mainLayersCount: number = _.size(layers);

        if (addBingMaps) layers.push(this.getBingMapsLayer());

        const layerGroup = new olLayerGroup({ layers });

        layerGroup.set('mainLayersCount', mainLayersCount);
        layerGroup.set('layerType', layerType);
        layerGroup.set('fieldsCount', fieldsCount);

        return layerGroup;
    };

    /**
     * Creates an XYZ image layer source
     * @param imageLayer The corresponding olLayerImage used for refreshing
     */
    private createXYZSource(imageLayer: olLayerImage, baseURL: string) {
        const tileSize = 256;

        const xyzOptions: any = {
            maxZoom: 17,
            minZoom: 13,
            // tileUrlFunction: ndviTileUrlFunction,
            url: `${baseURL}/{z}/{x}/{-y}.png`,
            crossOrigin: 'anonymous',
            tileSize: [tileSize, tileSize],
            tileLoadFunction: (tile: olImageTile, src: string) => {
                if (!src) {
                    console.warn('tileLoadFunction > empty src');
                    return;
                }

                const img: HTMLImageElement = tile.getImage() as HTMLImageElement;

                img.onload = () => {
                    setTimeout(() => {
                        imageLayer.getSource().changed();
                    }, REFRESH_DELAY);
                };

                img.src = src;
            },
        };

        const xyzSource = new olSourceXYZ(xyzOptions);

        xyzSource.on('tileloaderror', (error) => {
            if (this.isLoggingEnabled) console.log('xyzSource error', error.type);
        });

        return xyzSource;
    }
}
