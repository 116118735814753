<div class="Map-Wrapper">
    <shared-loading-screen
        [containerStyle]="{ background: '#0000002f', 'z-index': '99999' }"
        *ngIf="isLoading"
    ></shared-loading-screen>

    <div *ngIf="!mapView.isNdviAvailable" class="Ndvi-Unavailable">
        <nz-alert
            nzType="info"
            nzMessage="În curs de procesare"
            nzDescription="Imaginile de la satelit vor fi disponibile în următoarele 48-72 de ore"
            nzShowIcon
        >
        </nz-alert>
    </div>

    <map-view enableFieldsHovering [popupStyle]="popupStyle" #mapView></map-view>

    <div class="Top-Right-Toolbox">
        <div class="Search-Bar-Container">
            <map-search-bar id="search-bar" #searchBar [fitBoundingBox]="mapView.fitBoundingBox"></map-search-bar>
        </div>
    </div>

    <div class="Top-Left-Toolbox">
        <map-layer-type-selector></map-layer-type-selector>
    </div>

    <div class="Right-Toolbox">
        <map-zoom-buttons class="Zoom-Buttons" [handleZoomIn]="mapView.zoomIn" [handleZoomOut]="mapView.zoomOut">
        </map-zoom-buttons>
    </div>

    <map-center-buttons class="Center-Buttons" [handleFitFields]="mapView.fitBoundingBox"></map-center-buttons>
</div>
