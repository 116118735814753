import { Component, OnInit } from '@angular/core';
import olMap from 'ol/Map';
import olView from 'ol/View';
import olLayerImage from 'ol/layer/Image';
import { MapService } from '@core/services/map.service';
import { LayersService } from '@core/services/layers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take, switchMap } from 'rxjs/operators';
import Field from '@shared/models/field';
import { Extent as olExtent } from 'ol/extent';
import { NdviData } from '@shared/models/map/ndvi';
import { environment } from '@environments/environment';
import { format } from 'date-fns';
import _ from 'lodash';
import { WeatherService } from '@core/services/weather.service';
import { getRenderPixel } from 'ol/render';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'map-comparing-view',
    templateUrl: './comparing-view.component.html',
    styleUrls: ['./comparing-view.component.less'],
})
export class ComparingViewComponent implements OnInit {
    dividerPosition: number = window.innerWidth / 2;

    dates: {
        left: Date;
        right: Date;
    } = {
        left: null,
        right: null,
    };

    weatherData = { left: null, right: null };

    entries: NdviData[];

    private map: olMap;
    private view: olView;
    private leftVegetationLayer: olLayerImage;
    private rightVegetationLayer: olLayerImage;
    private fieldId: number;
    private initialRightDate: string = null;
    private boundingBox: olExtent;

    private isLoggingEnabled = !environment.production;

    constructor(
        private mapService: MapService,
        private layersService: LayersService,
        private activatedRoute: ActivatedRoute,
        private message: NzMessageService,
        private router: Router,
        private weatherService: WeatherService
    ) {}

    ngOnInit() {
        const divider = document.getElementById('Divider');

        // Half of the divider's width must be substracted
        divider.style.left = `${window.innerWidth / 2 - 14}px`;

        this.enableDragging(divider);

        this.activatedRoute.params
            .pipe(
                take(1),
                switchMap((params) => {
                    this.fieldId = Number.parseInt(params.fieldId);
                    this.initialRightDate = params.rightDate;

                    return this.layersService.getTimesliderData(this.fieldId);
                })
            )
            .subscribe(
                (entries: NdviData[]) => {
                    this.entries = entries.map((entry) => ({
                        ...entry,
                        status: this.getEntryStatus(entry),
                    }));
                    this.initializeMap();

                    if (this.isLoggingEnabled) console.log('[ComparingView] entries', this.entries);
                },
                (error: Error) => {
                    console.error(error);
                }
            );
    }

    getEntryStatus = (entry: NdviData): string => {
        if (entry) {
            const cloudyPixelsCount: number = entry.colour_bins.slice(-1).pop();
            const cloudsPercentage: number = cloudyPixelsCount / _.sum(entry.colour_bins);

            return cloudsPercentage >= 0.5 ? 'cloudy' : 'clear';
        }

        console.error('[ComparingViewComponent] getEntryStatus null entry');

        return 'cloudy';
    };

    initializeMap() {
        const bingMapsLayer = this.mapService.getBingMapsLayer();
        const field: Field = this.layersService.getField(this.fieldId);
        this.boundingBox = this.layersService.getBoundingBoxOfFieldsIds([this.fieldId]) as olExtent;

        const viewOptions = {
            zoom: 16,
            minZoom: 8,
            maxZoom: 19,
            projection: 'EPSG:4326',
        };

        this.leftVegetationLayer = this.mapService.createSingleVegetationLayer(field, null, 9);
        this.rightVegetationLayer = this.mapService.createSingleVegetationLayer(
            field,
            _.find(this.entries, (entry) => entry.date === this.initialRightDate),
            10
        );

        if (this.initialRightDate) {
            this.dates.right = new Date(this.initialRightDate);

            this.weatherService
                .getHistoricalWeatherData(this.fieldId, this.initialRightDate)
                .pipe(take(1))
                .subscribe((weatherData) => {
                    console.log(`(${this.initialRightDate}) Weather`, weatherData);
                    this.weatherData.right = weatherData;
                });
        }

        const outlineLayer = this.mapService.createSingleOutlineLayer(field);

        this.view = new olView(viewOptions);

        this.map = new olMap({
            view: this.view,
            layers: [
                bingMapsLayer,
                this.leftVegetationLayer,
                this.rightVegetationLayer,
                outlineLayer,
            ],
            controls: [],
            target: 'Map',
        });

        this.rightVegetationLayer.on('prerender', (event) => {
            const ctx = event.context;
            const mapSize = this.map.getSize();
            const width = this.dividerPosition;

            const tl = getRenderPixel(event, [width, 0]);
            const tr = getRenderPixel(event, [mapSize[0], 0]);
            const bl = getRenderPixel(event, [width, mapSize[1]]);
            const br = getRenderPixel(event, mapSize);

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(tl[0], tl[1]);
            ctx.lineTo(bl[0], bl[1]);
            ctx.lineTo(br[0], br[1]);
            ctx.lineTo(tr[0], tr[1]);
            ctx.closePath();
            ctx.clip();
        });

        this.leftVegetationLayer.on('prerender', (event) => {
            const ctx = event.context;
            const mapSize = this.map.getSize();
            const width = this.dividerPosition;

            const tl = getRenderPixel(event, [0, 0]);
            const tr = getRenderPixel(event, [width, 0]);
            const bl = getRenderPixel(event, [0, mapSize[1]]);
            const br = getRenderPixel(event, [width, mapSize[1]]);

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(tl[0], tl[1]);
            ctx.lineTo(bl[0], bl[1]);
            ctx.lineTo(br[0], br[1]);
            ctx.lineTo(tr[0], tr[1]);
            ctx.closePath();
            ctx.clip();
        });

        this.rightVegetationLayer.on('postrender', (event) => {
            const ctx = event.context;
            ctx.restore();
        });

        this.leftVegetationLayer.on('postrender', (event) => {
            const ctx = event.context;
            ctx.restore();
        });

        setTimeout(() => {
            const options = {
                duration: 0,
                constrainResolution: false,
                padding: [100, 100, 100, 100],
                maxZoom: 18,
            };

            this.view.fit(this.boundingBox, options);
        });
    }

    /**
     * Zooms in the main view by 1 zoom level
     */
    zoomIn = () => {
        this.view.animate({ zoom: this.view.getZoom() + 1, duration: 250 });
    };

    /**
     * Zooms out the main view by 1 zoom level
     */
    zoomOut = () => {
        this.view.animate({ zoom: this.view.getZoom() - 1, duration: 250 });
    };

    route = (): any => this.router.navigateByUrl(`/harti/${this.fieldId}/vizualizare/Vegetatie`);

    displayMessage = (type: string, message: string): any => this.message.create(type, message);

    isDateDisabled = (date: Date): boolean => {
        const isoDate: string = format(date, 'YYYY-MM-DD');

        const results: boolean[] = _(this.entries)
            .filter((entry) => entry.status === 'clear')
            .map((entry: NdviData) => entry.date === isoDate)
            .value();

        return _.isEmpty(_.compact(results));
    };

    onDateChange = (date: Date, identifier: 'left' | 'right'): void => {
        const isoDate: string = format(date, 'YYYY-MM-DD');

        const clearDates: string[] = _(this.entries)
            .filter((entry) => entry.status === 'clear')
            .map((entry: NdviData) => entry.date)
            .value();

        if (!clearDates.includes(isoDate)) {
            this.displayMessage('error', 'Nu există imagini pentru data selectată');
            this.dates[identifier] = null;

            // Set source as null in order to hide the tiles
            const imageLayer: olLayerImage =
                identifier === 'left' ? this.leftVegetationLayer : this.rightVegetationLayer;
            imageLayer.setSource(null);
            imageLayer.changed();

            return;
        }

        this.weatherService
            .getHistoricalWeatherData(this.fieldId, isoDate)
            .pipe(take(1))
            .subscribe((weatherData) => {
                console.log(`(${isoDate}) Weather`, weatherData);
                this.weatherData[identifier] = weatherData;
            });

        this.setNdviSource(isoDate, identifier);
    };

    private setNdviSource(date: string, identifier: 'left' | 'right'): void {
        console.log('[ComparingView] setNdviSource', date, identifier);

        const imageLayer: olLayerImage =
            identifier === 'left' ? this.leftVegetationLayer : this.rightVegetationLayer;
        const timesliderEntry = _.find(this.entries, (entry) => entry.date === date);

        this.mapService.setRasterSource(imageLayer, timesliderEntry);
    }

    private enableDragging = (element) => {
        let positionX = 0,
            initialPositionX = 0;

        element.onmousedown = (e) => {
            // tslint:disable-next-line: deprecation
            e = e || window.event;
            e.preventDefault();
            // Get the mouse cursor position at initialization
            initialPositionX = e.clientX;

            document.onmouseup = () => {
                document.onmouseup = null;
                document.onmousemove = null;
                this.map.render();
            };

            document.onmousemove = (event: any) => {
                // tslint:disable-next-line: deprecation
                event = event || window.event;
                event.preventDefault();

                // Calculate the new cursor position:
                positionX = initialPositionX - event.clientX;
                initialPositionX = event.clientX;

                // Set the element's new position:
                element.style.left = element.offsetLeft - positionX + 'px';

                // Half of the divider's width must be added
                this.dividerPosition = element.offsetLeft - positionX + 14;
                this.map.render();
            };
        };
    };
}
