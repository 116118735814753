import _ from 'lodash';
import {
    Component,
    OnInit,
    OnDestroy,
    TemplateRef,
    ViewChild,
    DestroyRef,
    inject,
} from '@angular/core';
import { take, takeUntil, switchMap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { downloadFile } from '@shared/utils/utils';
import { ClientsService } from '@modules/clients/services/clients.service';
import { LayersService } from '@core/services/layers.service';
import { OverviewService } from '@modules/map/services/overview.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Stats } from '@shared/components/field-stats/field-stats.component';

@Component({
    selector: 'map-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.less'],
})
export class OverviewComponent implements OnInit, OnDestroy {
    destroyRef = inject(DestroyRef);

    clientsCount = 0;
    clientsLoaded = 0;
    clientsFailed = 0;
    selectedYear: number;
    availableYears: number[];
    fieldStats: Stats;
    isLoadingStats = true;
    isDownloading = false;

    messageId: string;

    constructor(
        private message: NzMessageService,
        private overviewService: OverviewService,
        private layersService: LayersService,
        private clientsService: ClientsService,
    ) {}

    ngOnInit(): void {
        if (
            !_.isEqual(this.overviewService.OverviewClients, this.clientsService.Clients.getValue())
        ) {
            this.refreshData();
        }

        this.availableYears = this.overviewService.AvailableYears;

        this.messageId = this.message.loading('Se încarcă...', { nzDuration: 0 }).messageId;

        this.overviewService.CurrentYear.pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((currentYearData) => {
                this.isLoadingStats = true;
                this.selectedYear = currentYearData.year;
                this.clientsCount = currentYearData.ClientsCount;
                this.clientsLoaded = currentYearData.ClientsLoaded;
                this.clientsFailed = currentYearData.ClientsFailed;
                this.checkLoadingStatus();
                return this.layersService.getFieldsCropTypeStats(currentYearData.year);
            }),
        ).subscribe(
            (
                stats: [
                    {
                        apia_crop_type: number;
                        computed_area__sum: number;
                    },
                ],
            ) => {
                const statsWithCropTypes = _.map(stats, (item) => ({
                    cropType: this.layersService.getCropType(item.apia_crop_type),
                    area: item.computed_area__sum,
                }));
                const uniqueByName = {};
                _.forEach(statsWithCropTypes, (item) => {
                    if (uniqueByName[item.cropType.name]) {
                        uniqueByName[item.cropType.name].push(item.area);
                    } else {
                        uniqueByName[item.cropType.name] = [item.area];
                    }
                });
                const totalArea = _.sumBy(stats, (item) => item.computed_area__sum);
                const fieldStats: Stats = {
                    cropsStats: _(statsWithCropTypes)
                        .uniqBy('cropType.name')
                        .map((item) => {
                            const area: number = _.sum(uniqueByName[item.cropType.name]);
                            const percentage = (item.area * 100) / totalArea;
                            return {
                                cropType: item.cropType,
                                area,
                                width: percentage,
                                percentage,
                            };
                        })
                        .orderBy('area', 'desc')
                        .value(),
                };
                fieldStats.cropsStats.forEach((entry, index, array) => {
                    if (index > 0) {
                        entry.width += array[index - 1].width;
                    }
                });
                fieldStats.cropsStats = _.orderBy(fieldStats.cropsStats, 'width', 'desc');
                if (!_.isEmpty(fieldStats.cropsStats)) {
                    this.fieldStats = fieldStats;
                }
                this.isLoadingStats = false;
            },
            (err) => {
                this.isLoadingStats = false;
            },
        );
    }

    ngOnDestroy(): void {
        this.message.remove(this.messageId);
    }

    exportStats(): void {
        this.isDownloading = true;

        this.overviewService
            .exportStats()
            .pipe(take(1))
            .subscribe((base64File) => {
                const url = `data:application/vnd.ms-excel;base64,${base64File}`;
                fetch(url)
                    .then((res) => res.blob())
                    .then((blob: Blob) => {
                        const uri = URL.createObjectURL(blob);
                        const filename = `Culturi_Anul_${this.selectedYear}_${Date.now()}.xls`;
                        downloadFile(uri, filename);
                        this.isDownloading = false;
                    })
                    .catch((err) => (this.isDownloading = false));
            });
    }

    changeSelectedYear(availableYear: number) {
        this.overviewService.changeSelectedYear(availableYear);
    }

    refreshData(): void {
        this.overviewService.refreshData();
    }

    private checkLoadingStatus = (): void => {
        if (this.clientsLoaded + this.clientsFailed === this.clientsCount) {
            this.message.remove(this.messageId);
        }
    };
}
