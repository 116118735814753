import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { LayersService } from '@core/services/layers.service';
import { MapService } from '@core/services/map.service';
import { environment } from '@environments/environment';
import { AreaTag } from '@shared/models/enrollment/tags';
import Field from '@shared/models/field';
import { FieldInfo } from '@shared/models/field-info';
import { LayerItem, LayerType } from '@shared/models/map/layer';
import { MapPopupInfo } from '@shared/models/map/map-popup-info';
import { NdviData } from '@shared/models/map/ndvi';
import { getIsolationStyle } from '@shared/utils/styles';
import _ from 'lodash';
import olCollection from 'ol/Collection';
import olFeature from 'ol/Feature';
import olMap from 'ol/Map';
import olMapBrowserEvent from 'ol/MapBrowserEvent';
import olOverlay from 'ol/Overlay';
import olOverlayPositioning from 'ol/OverlayPositioning';
import olView from 'ol/View';
import { Coordinate as olCoordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import olGeometry from 'ol/geom/Geometry';
import olPolygon from 'ol/geom/Polygon';
import DragPan from 'ol/interaction/DragPan';
import DragRotate from 'ol/interaction/DragRotate';
import DragRotateAndZoom from 'ol/interaction/DragRotateAndZoom';
import olInteractionDraw from 'ol/interaction/Draw';
import olInteractionModify from 'ol/interaction/Modify';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import PinchRotate from 'ol/interaction/PinchRotate';
import PinchZoom from 'ol/interaction/PinchZoom';
import Pointer from 'ol/interaction/Pointer';
import olLayerGroup from 'ol/layer/Group';
import olLayerImage from 'ol/layer/Image';
import olLayerVector from 'ol/layer/Vector';
import olSourceVector from 'ol/source/Vector';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
    selector: 'map-view',
    templateUrl: './map-view.component.html',
    styleUrls: ['./map-view.component.less'],
    animations: [
        trigger('loadingAnimation', [
            transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class MapViewComponent implements OnInit, OnDestroy {
    destroyRef = inject(DestroyRef);

    static numberOfMaps = 0;

    /**
     * Popup container styles
     */
    @Input()
    popupStyle: any;

    /**
     * The starting and default layer type for this map
     */
    @Input()
    initialLayerType: LayerType = LayerType.Outline;

    /**
     * The padding used to fit the bounding box of the fields. Can be 'small' or 'large'. Default is 'medium'.
     */
    @Input()
    padding = 'medium';

    @Input()
    set enableFieldsHovering(value: Boolean) {
        this.isFieldsHoveringEnabled = value.toString() !== 'false';
    }

    @Input()
    set renderInFieldView(field: Field) {
        this.field = field;
        this.isFieldView = true;
    }

    mapId: number = MapViewComponent.numberOfMaps++;
    isFieldsHoveringEnabled = false;

    view: olView;
    map: olMap;
    fieldPopupInfo: MapPopupInfo;

    currentTimesliderEntry: NdviData = null;

    isNdviAvailable = true;
    isLoadingScreenVisible = true;

    /**
     * An object of type { LayerType: layerGroup } used for caching
     */
    private layerTypes: any = {};
    private isFieldView = false;
    private field: Field;

    /**
     * The feature which is currently hovered by the user
     */
    private highlightedFeature: olFeature;
    private boundingBox: Extent;
    private layerGroup: olLayerGroup;

    /**
     * Interaction-related
     */
    private drawnFeaturesSource = new olSourceVector();
    private drawInteraction: olInteractionDraw;
    private modifyInteraction: olInteractionModify;
    private vectorInteractionLayer: olLayerVector;
    private isolationStyle = getIsolationStyle();

    /**
     * An area or distance tag displayed above a currently drawn or modified polygon or lineString
     */
    private tagDiv: HTMLDivElement | Element;
    private tagOverlay: olOverlay;

    private isLoggingEnabled = !environment.production;

    constructor(
        private layersService: LayersService,
        private mapService: MapService,
        private router: Router,
    ) {}

    ngOnInit() {
        setTimeout(() => {
            this.initializeMap();
        });
    }

    ngOnDestroy() {}

    public setTimesliderSource = (timesliderEntry: NdviData): void => {
        if (this.isLoggingEnabled) console.log('[MapView] setTimesliderSource', timesliderEntry);

        this.currentTimesliderEntry = timesliderEntry;

        const currentLayerType: LayerType =
            this.layersService.currentLayerItem.getValue().layerType;
        const currentLayerGroup = this.layerTypes[currentLayerType] as olLayerGroup;

        if (!_.isEmpty(currentLayerGroup)) {
            currentLayerGroup.getLayers().forEach((imageLayer) => {
                // Obtain the layer which contains NDVI imagery
                if (imageLayer instanceof olLayerImage) {
                    this.mapService.setRasterSource(imageLayer, timesliderEntry);
                }
            });
        }
    };

    /**
     * Fits the bounding box of the visible layers
     * @param duration Animation duration
     */
    public fitBoundingBox = (boundingBox: Extent = this.boundingBox, duration: number = 600) => {
        let paddingValue: number;

        if (boundingBox && !boundingBox.includes(Infinity)) {
            switch (this.padding) {
                case 'small':
                    paddingValue = 40;
                    break;

                case 'large':
                    paddingValue = 140;
                    break;

                case 'huge':
                    paddingValue = 300;
                    break;

                default:
                    paddingValue = 100;
                    break;
            }

            const options = {
                duration,
                constrainResolution: false,
                padding: [paddingValue, paddingValue, paddingValue, paddingValue],
                maxZoom: 18,
            };

            this.view.fit(boundingBox, options);
        } else {
            console.error('MapView has no boundingBox to fit', boundingBox);
        }
    };

    /**
     * Zooms in the main view by 1 zoom level
     */
    public zoomIn = () => {
        this.view.animate({ zoom: this.view.getZoom() + 1, duration: 250 });
    };

    /**
     * Zooms out the main view by 1 zoom level
     */
    public zoomOut = () => {
        this.view.animate({ zoom: this.view.getZoom() - 1, duration: 250 });
    };

    /**
     * Enables drawing and toggles modifying interactions
     * @param options Options which will be used for the creation of the draw interaction (polygon by default)
     * @param restrictSingleFeature If true, the draw interaction will be removed after the first feature is drawn
     * @param enableModifying If true, drawn features can be modified after they are drawn
     */
    public enableDrawingInteractions = (
        options: any = {
            type: 'Polygon',
        },
        restrictSingleFeature: boolean = true,
        enableModifying: boolean = true,
    ): void => {
        if (this.isLoggingEnabled) console.log('enableDrawingInteractions');

        options['source'] = this.drawnFeaturesSource;
        options['style'] = this.isolationStyle;

        this.drawInteraction = new olInteractionDraw(options);
        this.map.addInteraction(this.drawInteraction);

        this.drawInteraction.on('drawstart', (evt) => {
            const feature: olFeature = evt['feature'];

            feature.getGeometry().on(
                'change',
                _.debounce((event) => {
                    // Handle the creation of a field area tag
                    this.setFieldAreaTag(event.target);
                }, 100),
            );
        });

        this.drawInteraction.on('drawend', (evt) => {
            if (restrictSingleFeature) {
                this.map.removeInteraction(this.drawInteraction);
                this.drawInteraction.setActive(false);
            }

            if (enableModifying) {
                this.modifyInteraction = new olInteractionModify({
                    source: this.drawnFeaturesSource,
                });
                this.map.addInteraction(this.modifyInteraction);
            }
        });

        this.vectorInteractionLayer = new olLayerVector({
            source: this.drawnFeaturesSource,
            zIndex: 999,
            style: this.isolationStyle,
        });

        this.map.addLayer(this.vectorInteractionLayer);
    };

    /**
     * Removes all interactions from the map, clears the drawn features source
     * and removes the vector layer which contains the source
     */
    public removeDrawingInteractions = (): void => {
        if (this.map) {
            if (this.isLoggingEnabled) console.log('removeDrawingInteractions');

            this.map.removeInteraction(this.drawInteraction);
            this.map.removeInteraction(this.modifyInteraction);

            this.clearMapOverlays();

            this.drawnFeaturesSource.clear();

            this.map.removeLayer(this.vectorInteractionLayer);
            this.map.changed();
        }
    };

    public getDrawnFeatureCoordinates = (): olCoordinate[][] => {
        let coordinates: olCoordinate[][] = null;

        const drawnFeaturesArray: olFeature[] = this.drawnFeaturesSource.getFeatures();

        if (!_.isEmpty(drawnFeaturesArray)) {
            const drawnGeometry = drawnFeaturesArray[0].getGeometry();

            if (drawnGeometry instanceof olPolygon) {
                coordinates = (drawnGeometry as olPolygon).getCoordinates();
            }
        }

        return coordinates;
    };

    public intersectDrawnFeatureWithPolygon = (coordinates: olCoordinate[][]): void => {
        if (this.isLoggingEnabled) console.log('intersectDrawnFeatureWithPolygon', coordinates);

        const drawnFeaturesArray: olFeature[] = this.drawnFeaturesSource.getFeatures();

        if (!_.isEmpty(drawnFeaturesArray) && coordinates) {
            const drawnGeometry = drawnFeaturesArray[0].getGeometry();

            if (drawnGeometry instanceof olPolygon) {
                const intersection: olCoordinate[][] = this.mapService.getPolygonsIntersection(
                    coordinates,
                    (drawnGeometry as olPolygon).getCoordinates(),
                );

                if (intersection) {
                    // Clear the drawnFeaturesSource and add the new feature with the resulting intersection
                    this.drawnFeaturesSource.clear();
                    const intersectedFeature: olFeature =
                        this.layersService.readFeature(intersection);

                    // Add the area tag creation event
                    intersectedFeature.getGeometry().on(
                        'change',
                        _.debounce((event) => {
                            // Handle the creation of a field area tag
                            this.setFieldAreaTag(event.target);
                        }, 100),
                    );

                    this.drawnFeaturesSource.addFeature(intersectedFeature);

                    // Reset the modify interaction so it works with the new feature
                    this.map.removeInteraction(this.modifyInteraction);
                    this.modifyInteraction = new olInteractionModify({
                        source: this.drawnFeaturesSource,
                    });
                    this.map.addInteraction(this.modifyInteraction);

                    // Trigger a change event on the new feature in order to initialize the area tag
                    intersectedFeature.getGeometry().changed();
                }
            }
        }
    };

    /**
     * Sets an Area tag overlay at the given position with the given value.
     * @param polygon The polygon which will be measured
     */
    private setFieldAreaTag = (polygon: olPolygon): void => {
        this.clearMapOverlays();

        // Create the area tag
        const area = this.layersService.getPolygonArea(polygon as olGeometry);
        const poleOfInaccessibility = this.layersService.getPoleOfInaccessibility(
            polygon.getCoordinates(),
        );

        if (area && poleOfInaccessibility) {
            const tag: AreaTag = {
                poleOfInaccessibility,
                area,
            };

            if (!this.tagDiv || !this.tagOverlay) {
                this.tagDiv = document.createElement('div');
                this.tagDiv.classList.add('Field-View-Area-Tag');

                this.tagOverlay = new olOverlay({
                    element: this.tagDiv as HTMLElement,
                    positioning: olOverlayPositioning.CENTER_CENTER,
                });
            }

            this.map.addOverlay(this.tagOverlay);

            this.tagDiv.innerHTML = `${tag.area.toFixed(2)} ha`.replace('.', ',');
            this.tagOverlay.setPosition(tag.poleOfInaccessibility);
        }
    };

    /**
     * Clears the map overlays
     */
    private clearMapOverlays(): olCollection<olOverlay> {
        const collection: olCollection<olOverlay> = this.map.getOverlays();
        collection.clear();

        return collection;
    }

    /**
     * Initializes the map
     */
    private initializeMap = (): void => {
        const viewOptions = {
            center: [28.0537149880826, 45.2379014113663] as [number, number],
            zoom: 12,
            minZoom: 7,
            maxZoom: 19,
            projection: 'EPSG:4326',
        };

        this.view = new olView(viewOptions);

        this.map = new olMap({
            target: `Map-View-Container-${this.mapId}`,
            view: this.view,
            layers: [],
            overlays: [],
            controls: [],
            interactions: [
                new DragPan(),
                new MouseWheelZoom(),
                new DragRotate(),
                new DragRotateAndZoom(),
                new Pointer(),
                new PinchZoom(),
                new PinchRotate(),
            ],
        });

        this.layersService.currentFilters
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.layersService.filterLayerGroup(this.layerGroup);
            });

        /**
         * When the Fields change, all the cached layerTypes should be erased.
         * The current layerType is reset to the first one in the list.
         */
        this.layersService.Fields.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.layerTypes = {};
            this.boundingBox = null;

            this.layersService.currentLayerItem.next(
                this.layersService.getLayerItem(this.initialLayerType),
            );
        });

        let currentLayerType: LayerType;

        this.layersService.currentLayerItem
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter((layerItem: LayerItem) => {
                    const layerType: LayerType = layerItem.layerType;

                    /**
                     * Only load cached layerGroups if the map is not rendered inside a FieldView component,
                     * because a timeslider date might change and require a new layerGroup to be used
                     */
                    if (!this.isFieldView && layerItem && this.layerTypes[layerType]) {
                        this.loadLayerGroup(this.layerTypes[layerType]);
                        return false;
                    } else {
                        currentLayerType = layerType;
                        return true;
                    }
                }),
                switchMap((layerItem: LayerItem) => {
                    switch (layerItem.layerType) {
                        case LayerType.Vegetatie:
                            /**
                             * If the map is rendered in FieldView, there's no need for obtaining ndviData.
                             * The ndvi layer will be created without a source, because the timeslider will
                             * set the source later on.
                             */
                            return this.isFieldView
                                ? of(
                                      this.mapService.createVegetationLayerGroup([this.field], {
                                          tilesURL:
                                              this.currentTimesliderEntry &&
                                              this.currentTimesliderEntry.tiles_url,
                                          addBingMaps: true,
                                      }),
                                  )
                                : this.layersService.getNdviIndex().pipe(
                                      switchMap((ndviData: { fieldId: NdviData }) => {
                                          return of(
                                              this.mapService.createVegetationLayerGroup(
                                                  this.layersService.Fields.getValue(),
                                                  {
                                                      ndviData,
                                                      addBingMaps: true,
                                                  },
                                              ),
                                          );
                                      }),
                                  );

                        // Outline
                        default:
                            return of(
                                this.mapService.createOutlineLayerGroup(
                                    this.layersService.Fields.getValue(),
                                    {
                                        addFieldDescriptors: true,
                                        addBingMaps: true,
                                    },
                                ),
                            );
                    }
                }),
            )
            .subscribe(
                (layerGroup: olLayerGroup) => {
                    if (layerGroup) {
                        this.loadLayerGroup(layerGroup);
                        this.layerTypes[currentLayerType] = layerGroup;
                    }
                },
                (error: Error) => {
                    console.error(error);
                },
            );

        // Hovering and clicking events are only enabled if needed
        if (this.isFieldsHoveringEnabled) {
            // Hovering event
            this.map.on('pointermove', (evt: olMapBrowserEvent) => {
                const featureLike = this.map.forEachFeatureAtPixel(evt.pixel, (f) => f, {
                    layerFilter: (layer) => layer.get('isFieldsLayer'),
                });

                this.handleHovering(featureLike as olFeature);
            });

            // Single click event (delayed for 250ms to prevent double-clicking)
            this.map.on('singleclick', (evt: olMapBrowserEvent) => {
                const feature: olFeature = this.map.forEachFeatureAtPixel(
                    evt.pixel,
                    (featureAtPixel) => {
                        if (this.isFieldsHoveringEnabled && featureAtPixel.get('isField'))
                            return featureAtPixel;
                    },
                ) as olFeature;

                if (feature && feature.get('isField')) {
                    this.handleFieldClick(feature);
                }
            });
        }
    };

    private handleFieldClick(feature: olFeature): void {
        const fieldInfo = feature.get('fieldInfo');

        if (fieldInfo && fieldInfo.fieldId) {
            switch (this.layersService.currentLayerItem.getValue().layerType) {
                case LayerType.Outline:
                    if (this.isLoggingEnabled)
                        console.log('Navigating to field details', fieldInfo.fieldId);
                    this.router.navigateByUrl(`/terenuri/${fieldInfo.fieldId}`);
                    break;

                default:
                    if (this.isLoggingEnabled)
                        console.log('Navigating to field view', fieldInfo.fieldId);
                    this.router.navigateByUrl(
                        `/harti/${fieldInfo.fieldId}/vizualizare/${
                            this.layersService.currentLayerItem.getValue().layerType
                        }`,
                    );
            }
        }
    }

    private handleHovering(feature: olFeature): void {
        if (feature !== this.highlightedFeature) {
            if (this.highlightedFeature) {
                this.highlightedFeature.set('hover', false);
            }

            if (feature) {
                // Handle field hovering
                const fieldInfo: FieldInfo = feature.get('fieldInfo');

                this.fieldPopupInfo = new MapPopupInfo({
                    name: fieldInfo.fieldName as string,
                    type: fieldInfo.cropType,
                    area: fieldInfo.fieldArea,
                    lastAvgNdvi: fieldInfo.lastAvgNdvi,
                    lastHistNdvi: fieldInfo.lastHistNdvi,
                    isAreaComputed: fieldInfo.isAreaComputed,
                });

                this.setCursor('pointer');
                feature.set('hover', true);
            } else {
                // Field hovering
                this.fieldPopupInfo = null;
                this.setCursor('auto');
            }

            this.highlightedFeature = feature;
        }
    }

    private setCursor(value: string): void {
        document.getElementById(this.map.getTarget() as string).style.cursor = value;
    }

    private loadLayerGroup(layerGroup: olLayerGroup): void {
        this.map.setLayerGroup(layerGroup);
        // Set the layerGroup to be used for filtering
        this.layerGroup = layerGroup;

        if (!this.boundingBox) {
            this.boundingBox = this.layersService.getBoundingBoxOfLayerGroup(layerGroup);
            this.fitBoundingBox(this.boundingBox, 0);

            if (this.isLoadingScreenVisible) this.isLoadingScreenVisible = false;
        }

        const currentLayerType: LayerType = layerGroup.get('layerType');
        const mainLayersCount: number = layerGroup.get('mainLayersCount');

        /**
         * Variable which triggers the 'NDVI is not available' overlay message
         */
        this.isNdviAvailable =
            (this.layersService.isNdviLayerType(currentLayerType) && mainLayersCount > 0) ||
            !this.layersService.isNdviLayerType(currentLayerType);
    }
}
