import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LayersService } from '@core/services/layers.service';
import Field from '@shared/models/field';
import _ from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { take, delay } from 'rxjs/operators';

@Component({
    selector: 'map-isolation-panel',
    templateUrl: './isolation-panel.component.html',
    styleUrls: ['./isolation-panel.component.less'],
})
export class IsolationPanelComponent implements OnInit, OnDestroy {
    @Input()
    field: Field;

    @Input()
    toggleIsolationMode: Function;

    @Input()
    enableMapViewInteractions: Function;

    @Input()
    disableMapViewInteractions: Function;

    @Input()
    intersectWithField: Function;

    @Input()
    getDrawnFeatureCoordinates: Function;

    isSaving = false;
    isModalVisible = false;

    child: Field;

    constructor(private message: NzMessageService, private layersService: LayersService) {}

    ngOnInit() {
        this.enableMapViewInteractions();
    }

    ngOnDestroy(): void {
        this.disableMapViewInteractions();
    }

    resetDrawingInteractions = (): void => {
        this.disableMapViewInteractions();
        this.enableMapViewInteractions();
    };

    openModal = (): void => {
        const coordinatesArray = this.getDrawnFeatureCoordinates();

        if (!coordinatesArray) {
            this.displayMessage('warning', 'Desenează un poligon pentru a putea salva');
            return;
        }

        const coordinatesWkt: string =
            this.layersService.convertPolygonCoordinatesArrayToWkt(coordinatesArray);

        if (coordinatesWkt) {
            this.child = _.cloneDeep(this.field);

            this.child.parent = this.field.id;
            this.child.geometry = coordinatesWkt;
            this.child.name = 'Daună';

            delete this.child.id;
            delete this.child.computed_area;

            this.isModalVisible = true;
        } else {
            this.displayMessage('error', 'Poligon invalid');
            return;
        }
    };

    savePolygon = (): void => {
        console.log('> Save polygon', this.child);

        if (this.child.name.trim().length === 0) {
            this.displayMessage('warning', 'Poligonul trebuie să aibă o denumire');
            return;
        }

        this.isSaving = true;

        this.layersService
            .createFields([this.child])
            .pipe(delay(100), take(1))
            .subscribe(
                (result) => {
                    console.log(result);
                    this.displayMessage('success', 'Poligonul a fost salvat');
                    this.isSaving = false;
                    this.isModalVisible = false;
                    this.toggleIsolationMode(false);
                },
                (error) => {
                    this.displayMessage('error', 'A apărut o eroare la salvare');
                    console.error(error);
                    this.isSaving = false;
                }
            );
    };

    displayMessage = (type: string, message: string): any => this.message.create(type, message);
}
