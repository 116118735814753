/**
 * @module ol/layer/TileProperty
 */
/**
 * @enum {string}
 */
export default {
    PRELOAD: 'preload',
    USE_INTERIM_TILES_ON_ERROR: 'useInterimTilesOnError',
};
