<button nz-button nz-dropdown [nzDropdownMenu]="menu" [disabled]="isDisabled" class="Dropdown">
    {{ currentLayerItem?.name }} <i nz-icon nzType="down"></i>
</button>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu class="Layer-Type-List">
        <li
            *ngFor="let layerType of layerItems; let i = index"
            nz-menu-item
            (click)="setLayerType(layerType)"
        >
            <a>
                <div class="flex items-center">
                    <img
                        class="Thumbnail"
                        src="/assets/img/layerItems/{{ layerType.iconFileName }}"
                        width="48"
                        height="48"
                    />

                    <div class="ml-3" style="font-size: 16px">
                        {{ layerType.name }}
                    </div>
                </div>
            </a>
        </li>
    </ul>
</nz-dropdown-menu>
