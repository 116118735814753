<div #timeslider [ngStyle]="{ transform: 'translateX(' + position + 'px)' }" class="Timeslider">
    <div *ngFor="let entry of timesliderData">
        <!-- Clear date -->
        <div *ngIf="entry.date" class="Item" (click)="set(entry)">
            <span class="Date">{{ entry.date | date: 'dd MMM' | titlecase }}</span>
            <map-mini-map-view
                enableLoadingScreen
                padding="small"
                fieldId="{{ fieldId }}"
                tilesURL="{{ entry.tiles_url }}"
                [layerType]="LayerType.Vegetatie"
                [containerStyle]="mapStyle"
                [isActive]="entry === currentTimesliderEntry"
                [loadingScreenStyle]="loadingScreenStyle"
                hideBingMapsLayer
            ></map-mini-map-view>
        </div>

        <!-- Cloudy interval -->
        <div *ngIf="!entry.date" class="Clouds-Item">
            <div [ngStyle]="mapStyle" class="Box">
                <div class="Cloud-Wrapper"><i class="Cloud" nz-icon [inlineSVG]="'/assets/icons/cloud.svg'"></i></div>

                <div class="Interval-Wrapper">
                    <div>{{ entry.start | date: 'dd MMM' | titlecase }}</div>
                    <div *ngIf="entry.start != entry.end">{{ entry.end | date: 'dd MMM' | titlecase }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
