import {
    Component,
    DestroyRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LayersService } from '@core/services/layers.service';
import { MapService } from '@core/services/map.service';
import Field from '@shared/models/field';
import { LayerItem } from '@shared/models/map/layer';
import _ from 'lodash';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'map-layer-type-selector',
    templateUrl: './layer-type-selector.component.html',
    styleUrls: ['./layer-type-selector.component.less'],
})
export class LayerTypeSelectorComponent implements OnInit, OnDestroy, OnChanges {
    destroyRef = inject(DestroyRef);

    @Input()
    isDisabled = false;

    currentLayerItem: LayerItem;
    layerItems: Array<LayerItem>;

    constructor(
        private layersService: LayersService,
        private mapService: MapService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isDisabled) {
            this.isDisabled = changes.isDisabled.currentValue as boolean;
        }
    }

    ngOnInit() {
        this.layerItems = this.layersService.layerItems;
        this.layersService.currentLayerItem.next(this.layerItems[0]);

        this.layersService.Fields.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (fields: Field[]) => (this.isDisabled = _.isEmpty(fields)),
        );

        this.layersService.currentLayerItem
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter((layer) => !!layer),
            )
            .subscribe((incomingLayer: LayerItem) => (this.currentLayerItem = incomingLayer));
    }

    ngOnDestroy() {}

    /**
     * Sets a new layer type
     * @param layerType New layer type object
     */
    setLayerType(layerType: LayerItem) {
        if (layerType.layerType !== this.layersService.currentLayerItem.getValue().layerType) {
            this.layersService.currentLayerItem.next(layerType);
        }
    }
}
