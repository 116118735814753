import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    HostListener,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { take } from 'rxjs/operators';
import _ from 'lodash';
import { environment } from '@environments/environment';
import { LayerType } from '@shared/models/map/layer';
import { NdviData } from '@shared/models/map/ndvi';
import { LayersService } from '@core/services/layers.service';
import { isWithinInterval } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'map-time-slider',
    templateUrl: './time-slider.component.html',
    styleUrls: ['./time-slider.component.less'],
})
export class TimeSliderComponent implements OnInit {
    @Input()
    fieldId;

    @Input()
    setTimesliderSource: Function;

    @Input()
    initialDate;

    @Input()
    getDateRange: Function;

    @Output()
    currentDate = new EventEmitter<NdviData>();

    mapSize = '80px';

    mapStyle = {
        width: this.mapSize,
        height: this.mapSize,
        border: '1px solid gray',
        'background-color': '#00000066',
    };

    loadingScreenStyle = {
        background: 'rgba(0, 0, 0, 0.6)',
    };

    position = 0;

    LayerType = LayerType;

    timesliderData: NdviData[];
    entries: NdviData[];

    currentTimesliderEntry;
    alerts: object = {};

    timesliderWidth: any;
    entryMargin: number;

    initialDateRange: Date[];

    private entryWidth: number;
    private screenWidth: number;

    private isLoggingEnabled = !environment.production;

    @ViewChild('timeslider') timeslider: ElementRef;

    @HostListener('window:resize', ['$event'])
    onResize(_event?: any) {
        this.screenWidth = window.innerWidth;
    }

    constructor(
        private layersService: LayersService,
        private message: NzMessageService,
        private changeDetectionRef: ChangeDetectorRef
    ) {
        this.onResize();
    }

    ngOnInit() {
        this.layersService
            .getTimesliderData(this.fieldId)
            .pipe(take(1))
            .subscribe(
                (entries: NdviData[]) => {
                    this.entries = entries.map((entry) => ({
                        ...entry,
                        status: this.getEntryStatus(entry),
                    }));
                    if (this.isLoggingEnabled) console.warn('[TimeSlider]', this.entries);

                    // Initialize the timeslider by obtaining the initial dateRange from FieldView
                    this.setTimesliderInterval(this.getDateRange());
                },
                (error: Error) => {
                    console.error(error);
                }
            );
    }

    /**
     * Initializes the timeslider with dates within the given range.
     * If no clear dates exist in the given range, the timeslider will be empty.
     * @param range Time range consisting of 2 dates
     */
    public setTimesliderInterval = (range: Date[]): void => {
        if (!_.isEmpty(this.entries)) {
            const entriesInRange: NdviData[] = this.entries.filter((entry: NdviData) =>
                isWithinInterval(new Date(entry.date), { start: range[0], end: range[1] })
            );

            const clearEntries: NdviData[] = entriesInRange.filter(
                (entry) => entry.status === 'clear'
            );

            // Parse the timeslider data based on the filtered entries
            this.timesliderData = this.parseTimesliderData(entriesInRange);
            this.changeDetectionRef.detectChanges();

            // if (this.isLoggingEnabled) console.log('[TimeSliderComponent] timesliderData', this.timesliderData);

            // The initial entry is by default the most clear entry in the selected date range
            let initialEntry: NdviData = _.head(clearEntries);

            // if (this.isLoggingEnabled) console.log('[TimeSliderComponent] initialEntry', initialEntry);

            // If an initialDate is provided, it is set as the initial entry
            if (this.initialDate) {
                const searchedEntry: NdviData = _.find(
                    clearEntries,
                    (entry) => entry.date === this.initialDate
                );

                if (!searchedEntry) {
                    this.displayMessage('warning', 'Nu există imagini pentru data selectată');
                } else {
                    initialEntry = searchedEntry;
                }

                // Set to null so that the initialEntry is only set on timeslider initialization
                this.initialDate = null;
            }

            if (initialEntry) {
                // Takes into account that the timeslider element may not be rendered instantly
                setTimeout(() => this.set(initialEntry), 500);
            } else if (
                this.layersService.currentLayerItem.getValue().layerType === LayerType.Vegetatie
            ) {
                this.displayMessage('warning', 'Nu există imagini în intervalul selectat');

                // Center the timeslider
                this.position = this.screenWidth / 2 - this.entryWidth / 2;

                this.currentDate.emit(null);
            }
        }
    };

    /**
     * Parse the timeslider array and organize cloudy dates into intervals.
     */
    parseTimesliderData(entries: NdviData[]) {
        const reversedEntries = entries.slice().reverse();

        const processedEntries = [];
        let startingDate: string = null;

        for (let i = 0; i < reversedEntries.length; i++) {
            const entry = reversedEntries[i];

            if (entry.status === 'cloudy') {
                if (!startingDate) startingDate = entry.date;
            }

            if (entry.status === 'clear') {
                if (startingDate) {
                    processedEntries.push({
                        start: startingDate,
                        end: reversedEntries[i - 1].date,
                    });

                    startingDate = null;
                }

                processedEntries.push(entry);
            }

            // If the end of the array is reached
            if (i === reversedEntries.length - 1) {
                if (startingDate) {
                    processedEntries.push({
                        start: startingDate,
                        end: entry.date,
                    });
                }
            }
        }

        const timesliderData = processedEntries.reverse();

        return timesliderData;
    }

    set(entry: NdviData): void {
        this.moveTimeslider(entry.id);

        if (entry.status === 'clear') {
            this.setTimesliderSource(entry);
            this.currentDate.emit(entry);
        }
    }

    moveTimeslider(targetId: number): void {
        if (!this.entryWidth) {
            // The width of the element + the margin of the Item class
            const style = window.getComputedStyle(
                document.getElementsByClassName('Item').item(0)
            ).marginLeft;

            this.entryMargin = Number.parseFloat(style.replace('px', ''));
            this.entryWidth =
                2 * this.entryMargin +
                (document.getElementsByClassName('Item').item(0) as HTMLElement).offsetWidth;
        }

        this.timesliderWidth = this.timeslider.nativeElement.offsetWidth;

        const indexOfEntry: number = _.findIndex(
            this.timesliderData,
            (entry) => entry.id === targetId
        );
        let futurePosition =
            -this.timesliderWidth +
            indexOfEntry * this.entryWidth +
            this.screenWidth / 2 +
            this.entryWidth / 2;

        futurePosition = Math.min(0 + this.entryMargin, futurePosition);
        futurePosition = Math.max(
            -this.timesliderWidth + this.screenWidth - this.entryMargin,
            futurePosition
        );

        this.position = futurePosition;
        this.currentTimesliderEntry = this.timesliderData[indexOfEntry];
    }

    displayMessage = (type: string, message: string): any => this.message.create(type, message);

    getEntryStatus = (entry: NdviData): string => {
        if (entry) {
            const cloudyPixelsCount: number = entry.colour_bins.slice(-1).pop();
            const cloudsPercentage: number = cloudyPixelsCount / _.sum(entry.colour_bins);

            return cloudsPercentage >= 0.5 ? 'cloudy' : 'clear';
        }

        console.error('[TimeSliderComponent] getEntryStatus null entry');

        return 'cloudy';
    };
}
