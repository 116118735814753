/**
 * @module ol/MapProperty
 */
/**
 * @enum {string}
 */
export default {
    LAYERGROUP: 'layergroup',
    SIZE: 'size',
    TARGET: 'target',
    VIEW: 'view',
};
