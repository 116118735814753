<div class="Header" *ngIf="isReadyToInitialize">
    <a (click)="route()" class="Back-Button"
        ><i class="mr-1 text-sm" nz-icon nzType="arrow-left" theme="outline"></i
        ><span>Hărți</span></a
    >
    <div class="Field-Info-Container">
        <div class="Field-Info-Wrapper">
            <div
                class="Flex-Center-All Field-Name"
                nz-popover
                nzPopoverTrigger="click"
                [nzPopoverTitle]="titleTemplate"
                [nzPopoverContent]="contentTemplate"
            >
                <div>{{ field?.name }}</div>
                <i class="Chevron-Down" nz-icon nzType="down" nzTheme="outline"></i>
            </div>

            <div
                class="Crop-Type"
                [style.color]="cropType.field_color == '#000000' ? '#fff' : cropType.field_color"
            >
                {{ cropType?.name }}
            </div>

            <ng-template #titleTemplate>
                <div class="Flex-Center-All">
                    <a class="Field-Name-Link" routerLink="/terenuri/{{ field.id }}">
                        {{ field?.name }}
                    </a>
                </div>
            </ng-template>
            <ng-template #contentTemplate>
                <div class="Field-Changer-Content Flex-Column">
                    <map-field-search-list
                        [onFieldClick]="onSearchedFieldClick"
                        constrainHeight
                    ></map-field-search-list>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="Current-Date-Header" [ngStyle]="ndviComponentStyle">
    <div class="Current-Date">
        {{ currentTimesliderEntry?.date | date: 'dd MMM y' | titlecase }}
    </div>
</div>

<div class="Map-Container" *ngIf="isReadyToInitialize">
    <div *ngIf="!mapView.isNdviAvailable" class="Ndvi-Unavailable">
        <nz-alert
            nzType="info"
            nzMessage="În curs de procesare"
            nzDescription="Imaginile de la satelit vor fi disponibile în următoarele 48-72 de ore"
            nzShowIcon
        >
        </nz-alert>
    </div>

    <map-view
        [renderInFieldView]="field"
        [initialLayerType]="initialLayerType"
        padding="huge"
        #mapView
    ></map-view>

    <div class="Top-Left-Toolbox Flex-Column">
        <map-layer-type-selector [isDisabled]="isIsolationModeActive"></map-layer-type-selector>

        <shared-map-legend
            [ngStyle]="ndviComponentStyle"
            [entry]="currentTimesliderEntry"
        ></shared-map-legend>

        <shared-weather-legend
            *ngIf="currentTimesliderEntry"
            [data]="weatherData"
            [ngStyle]="ndviComponentStyle"
        ></shared-weather-legend>
    </div>

    <div class="Top-Right-Toolbox">
        <nz-range-picker
            [ngStyle]="ndviComponentStyle"
            [(ngModel)]="dateRange"
            nzFormat="dd-MMM-yyyy"
            [nzPlaceHolder]="['Dată de început', 'Dată de sfârșit']"
            (ngModelChange)="onDateChange($event)"
            [nzAllowClear]="false"
        ></nz-range-picker>

        <div class="Top-Right-UI-Container" [ngStyle]="ndviComponentStyle">
            <button nz-button nzType="default" (click)="goToComparingView()">
                <i nz-icon [nzType]="'scan'" [nzTheme]="'outline'" [nzRotate]="90"></i>
                Compară
            </button>
        </div>

        <div class="Top-Right-UI-Container" [ngStyle]="ndviComponentStyle">
            <button
                nz-button
                nzType="default"
                [disabled]="
                    isIsolationModeActive || !permissions.includes('core.manage_isolated_areas')
                "
                (click)="toggleIsolationMode(true)"
            >
                <i nz-icon nzType="radius-upright" nzTheme="outline"></i>
                Izolează
            </button>
        </div>

        <map-isolation-panel
            class="Top-Right-UI-Container"
            *ngIf="isIsolationModeActive"
            [field]="field"
            [toggleIsolationMode]="toggleIsolationMode"
            [enableMapViewInteractions]="mapView.enableDrawingInteractions"
            [disableMapViewInteractions]="mapView.removeDrawingInteractions"
            [intersectWithField]="intersectIsolationPolygonWithField"
            [getDrawnFeatureCoordinates]="mapView.getDrawnFeatureCoordinates"
        ></map-isolation-panel>
    </div>

    <div class="Right-Toolbox">
        <map-zoom-buttons
            class="Zoom-Buttons"
            [handleZoomIn]="mapView.zoomIn"
            [handleZoomOut]="mapView.zoomOut"
        ></map-zoom-buttons>
    </div>

    <map-time-slider
        [ngStyle]="timesliderStyle"
        class="Timeslider"
        [fieldId]="field.id"
        [setTimesliderSource]="mapView.setTimesliderSource"
        [getDateRange]="getDateRange"
        (currentDate)="setCurrentTimesliderDate($event)"
        [initialDate]="initialTimesliderDate"
        #timeslider
    >
    </map-time-slider>
</div>
