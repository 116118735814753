<a (click)="route()" class="Back-Button On-Top"
    ><i nz-icon nzType="left" theme="outline"></i><span>Vizualizare teren</span></a
>

<div class="Top-Toolbar">
    <div class="Toolbar-Column On-Top">
        <nz-date-picker
            class="Pointer-Events"
            nzFormat="dd-MMMM-yyyy"
            [nzDisabledDate]="isDateDisabled"
            [(ngModel)]="dates['left']"
            (ngModelChange)="onDateChange($event, 'left')"
            nzPlaceHolder="Selectează o dată"
            [nzAllowClear]="false"
        >
        </nz-date-picker>
    </div>

    <div class="Toolbar-Column On-Top">
        <nz-date-picker
            class="Pointer-Events"
            nzFormat="dd-MMMM-yyyy"
            [nzDisabledDate]="isDateDisabled"
            [(ngModel)]="dates['right']"
            (ngModelChange)="onDateChange($event, 'right')"
            nzPlaceHolder="Selectează o dată"
            [nzAllowClear]="false"
        >
        </nz-date-picker>
    </div>
</div>

<div class="Map-View-Wrapper">
    <div id="Divider" class="Divider">
        <i nz-icon nzType="caret-left" nzTheme="outline"></i>
        <i nz-icon nzType="caret-right" nzTheme="outline"></i>
    </div>

    <div id="Map" class="Map-View"></div>
</div>

<div class="Divider-Filter" [style.left]="dividerPosition + 'px'"></div>

<div class="Right-Toolbox">
    <map-zoom-buttons class="Zoom-Buttons On-Top" [handleZoomIn]="zoomIn" [handleZoomOut]="zoomOut"></map-zoom-buttons>
</div>

<div class="Bottom-Toolbar">
    <div class="Toolbar-Column On-Top">
        <shared-weather-legend
            *ngIf="dates.left"
            [data]="weatherData.left"
            class="Pointer-Events"
        ></shared-weather-legend>
    </div>

    <div class="Toolbar-Column On-Top">
        <shared-weather-legend
            *ngIf="dates.right"
            [data]="weatherData.right"
            class="Pointer-Events"
        ></shared-weather-legend>
    </div>
</div>
