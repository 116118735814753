import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ClientSet } from '@shared/models/client';

@Component({
    selector: 'map-overview-popup',
    templateUrl: './overview-popup.component.html',
    styleUrls: ['./overview-popup.component.less'],
})
export class OverviewPopupComponent implements OnChanges {
    @Input()
    clientSet: ClientSet;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.clientSet) {
            this.clientSet = changes.clientSet.currentValue;
        }
    }
}
