import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MapComponent } from './components/map/map.component';
import { FieldViewComponent } from './components/field-view/field-view.component';
import { ComparingViewComponent } from './components/comparing-view/comparing-view.component';
import { OverviewComponent } from './components/overview/overview.component';
import { OverviewGuard } from './guards/overview.guard';

const routes: Routes = [
    {
        path: ':fieldId/vizualizare/:layerType/:initialTimesliderDate',
        component: FieldViewComponent,
    },
    {
        path: ':fieldId/vizualizare/:layerType',
        component: FieldViewComponent,
    },
    {
        path: ':fieldId/comparatie/:rightDate',
        component: ComparingViewComponent,
    },
    {
        path: ':fieldId/comparatie',
        component: ComparingViewComponent,
    },
    {
        path: 'clienti',
        canActivate: [OverviewGuard],
        canLoad: [OverviewGuard],
        component: OverviewComponent,
    },
    {
        path: '',
        component: MapComponent,
    },
    {
        path: '**',
        component: MapComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MapRoutingModule {}
