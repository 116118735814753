import { Component, Input } from '@angular/core';

@Component({
    selector: 'map-center-buttons',
    templateUrl: './center-buttons.component.html',
    styleUrls: ['./center-buttons.component.less']
})
export class CenterButtonsComponent {
    @Input()
    handleFitFields: Function;

    constructor() {}

    centerFields() {
        if (this.handleFitFields) this.handleFitFields();
    }
}
