import olStyle from 'ol/style/Style';
import olStroke from 'ol/style/Stroke';
import olFill from 'ol/style/Fill';
import olStyleCircle from 'ol/style/Circle';
import olStyleText from 'ol/style/Text';
import olStyleIcon from 'ol/style/Icon';
import CropType from '@shared/models/crop-type';
import { FillType } from '@shared/models/utils-models';
import { FieldInfo } from '@shared/models/field-info';
import olFeature from 'ol/Feature';

const strokeWidth = 1.5;

const fieldDescriptorsStyles: {
    cropTypeId?: olStyle;
} = {};

const outlineStyles: any = {
    default: new olStyle({
        stroke: new olStroke({
            color: [0, 0, 0, 1],
            width: strokeWidth,
        }),
        fill: new olFill({ color: [0, 0, 0, 0.25] }),
        zIndex: 1,
    }),
    isEditingShape: new olStyle({
        stroke: new olStroke({
            color: [250, 250, 250, 1],
            width: strokeWidth,
        }),
        fill: new olFill({ color: [250, 250, 250, 0] }),
        zIndex: 2,
    }),
    isMergingShape: new olStyle({
        stroke: new olStroke({
            color: [30, 144, 255, 1],
            width: strokeWidth,
        }),
        fill: getHashFillStyle([30, 144, 255, 1]),
        zIndex: 2,
    }),
    markedForDeletion: new olStyle({
        stroke: new olStroke({
            color: [255, 0, 0, 1],
            width: strokeWidth,
        }),
        fill: getHashFillStyle([255, 0, 0, 1]),
        zIndex: 2,
    }),
    markedForSplitting: new olStyle({
        stroke: new olStroke({
            color: [242, 101, 34, 1],
            width: strokeWidth,
        }),
        fill: getHashFillStyle([242, 101, 34, 1]),
        zIndex: 2,
    }),
    secondaryMergingField: new olStyle({
        stroke: new olStroke({
            color: [200, 50, 230, 1],
            width: strokeWidth,
        }),
        fill: getHashFillStyle([200, 50, 230, 1]),
        zIndex: 2,
    }),
    markedForMerging: new olStyle({
        stroke: new olStroke({
            color: [200, 50, 230, 1],
            width: strokeWidth,
        }),
        fill: getHashFillStyle([200, 50, 230, 1]),
        zIndex: 2,
    }),
    markedForShapeEditing: new olStyle({
        stroke: new olStroke({
            color: [250, 250, 250, 1],
            width: strokeWidth,
        }),
        fill: getHashFillStyle([250, 250, 250, 1]),
        zIndex: 2,
    }),
    markedForRenaming: new olStyle({
        stroke: new olStroke({
            color: [56, 178, 99, 1],
            width: strokeWidth,
        }),
        fill: getHashFillStyle([56, 178, 99, 1]),
        zIndex: 2,
    }),
};

const cropTypeOutlineStyles: {
    cropTypeId?: {
        default: olStyle;
        hover: olStyle;
        focus: olStyle;
    };
} = {};

const outlineStrokeStyles = {
    hover: new olStroke({
        color: [27, 29, 41, 0.9],
        lineDash: [10, 10],
        width: strokeWidth + 1,
    }),
    focus: new olStroke({
        color: [255, 255, 255, 1],
        width: strokeWidth + 1,
    }),
};

const simpleStyles = {
    default: new olStyle({
        stroke: new olStroke({
            color: [9, 177, 185, 1],
            width: strokeWidth,
        }),
        fill: new olFill({
            color: [9, 177, 185, 0.25],
        }),
        zIndex: 1,
    }),
    hover: new olStyle({
        stroke: new olStroke({
            color: [255, 96, 54, 1],
            width: strokeWidth,
        }),
        fill: new olFill({
            color: [255, 96, 54, 0.25],
        }),
        zIndex: 2,
    }),
};

export const outlineStyle = (feature: olFeature, _: any): olStyle => {
    const fieldInfo: FieldInfo = feature.get('fieldInfo');
    let style: olStyle = outlineStyles.default;

    if (feature.get('isEditingShape')) {
        style = outlineStyles.isEditingShape;
    } else if (feature.get('isMergingShape')) {
        style = outlineStyles.isMergingShape;
    } else if (feature.get('markedForDeletion')) {
        style = outlineStyles.markedForDeletion;
    } else if (feature.get('markedForSplitting')) {
        style = outlineStyles.markedForSplitting;
    } else if (feature.get('secondaryMergingField')) {
        style = outlineStyles.secondaryMergingField;
    } else if (feature.get('markedForMerging')) {
        style = outlineStyles.markedForMerging;
    } else if (feature.get('markedForShapeEditing')) {
        style = outlineStyles.markedForShapeEditing;
    } else if (feature.get('markedForRenaming')) {
        style = outlineStyles.markedForRenaming;
    } else {
        let type = 'default';

        // Focused field
        if (feature.get('focus')) {
            type = 'focus';
        } else if (feature.get('hover') && !feature.get('markedForShapeEditing')) {
            type = 'hover';
        } else if (feature.get('vegetation')) {
            type = 'vegetation';
        }

        style = getCropTypeOutlineStyle(fieldInfo.cropType, type);
    }

    return style;
};

export const simpleStyle = (feature: olFeature, _: any): olStyle => {
    const type = feature.get('hover') ? 'hover' : 'default';
    return simpleStyles[type];
};

const interactionStyle = new olStyle({
    fill: new olFill({
        color: [200, 50, 230, 0.35],
    }),
    stroke: new olStroke({
        color: [200, 50, 230, 1],
        width: 3,
    }),
    image: new olStyleCircle({
        radius: 5,
        fill: new olFill({
            color: [200, 50, 230, 1],
        }),
    }),
});

export function getInteractionStyle(): olStyle {
    return interactionStyle;
}

const isolationStyle = new olStyle({
    fill: getHashFillStyle([200, 50, 230, 1]),
    stroke: new olStroke({
        color: [200, 50, 230, 1],
        width: 2,
    }),
    image: new olStyleCircle({
        radius: 5,
        fill: new olFill({
            color: [200, 50, 230, 1],
        }),
    }),
});

export function getIsolationStyle(): any {
    return isolationStyle;
}

const measuringLineStyle = new olStyle({
    stroke: new olStroke({
        color: [255, 204, 51, 0.75],
        lineDash: [5, 5],
        width: 2,
    }),
    image: new olStyleCircle({
        radius: 4,
        stroke: new olStroke({
            color: [255, 255, 255, 1],
        }),
        fill: new olFill({
            color: [255, 255, 255, 0],
        }),
    }),
});

export function getMeasuringLineStyle() {
    return measuringLineStyle;
}

const accuracyFeatureStyle = new olStyle({
    image: new olStyleCircle({
        radius: 1,
        fill: new olFill({ color: [51, 153, 204, 1] }),
        stroke: new olStroke({ color: [51, 51, 204, 1], width: 3 }),
    }),
});

export function getAccuracyFeatureStyle() {
    return accuracyFeatureStyle;
}

const positionFeatureStyle = new olStyle({
    image: new olStyleCircle({
        radius: 9,
        fill: new olFill({
            color: [51, 153, 204, 1],
        }),
        stroke: new olStroke({
            color: [255, 255, 255, 1],
            width: 2,
        }),
    }),
});

export function getPositionFeatureStyle() {
    return positionFeatureStyle;
}

export const getFieldDescriptorStyle = (cropType: CropType): olStyle => {
    if (!fieldDescriptorsStyles[cropType.id]) {
        fieldDescriptorsStyles[cropType.id] = new olStyle({
            image: new olStyleCircle({
                radius: 13,
                fill: new olFill({ color: getFieldColor(cropType, FillType.TranslucidStrong) }),
            }),
            text: new olStyleText({
                font: 'normal normal 500 ' + 11 + 'px Open Sans, sans-serif',
                fill: new olFill({ color: cropType.text_color }),
                text: getDescriptorText(cropType),
                textAlign: 'center',
                textBaseline: 'middle',
            }),
        });
    }

    return fieldDescriptorsStyles[cropType.id];
};

const pinFeatureStyle = new olStyle({
    image: new olStyleIcon({
        anchor: [0.5, 0.5],
        src: 'assets/icons/pin.png',
    }),
});

export function getPinFeatureStyle(): olStyle {
    return pinFeatureStyle;
}

/**
 * Transforms a color from HEX format in RGB format
 * @param hex Color in hex format (e.g. #FFFF00 or shorthand #FF0)
 */
function hexToRgb(hex: string): [number, number, number, number] {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    try {
        hex = hex.replace(shorthandRegex, (m, red, green, blue) => {
            return red + red + green + green + blue + blue;
        });
    } catch (error) {
        console.error(error);
        return [0, 0, 0, 1];
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) return null;

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return [r, g, b, 1];
}

function getDescriptorText(cropType: CropType): string {
    switch (cropType.name) {
        case 'TEREN NECULTIVAT':
            return 'NE';

        case 'null':
            return '?';

        case null:
            return '?';

        case 'Nespecificat':
            return 'NE';

        default:
            return cropType.name.slice(0, 2);
    }
}

function createHashPattern(color: [number, number, number, number]) {
    const cnv = document.createElement('canvas');
    const ctx = cnv.getContext('2d');
    cnv.width = 20;
    cnv.height = 20;
    ctx.fillStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.85)`;

    for (let i = 0; i < 10; ++i) {
        ctx.fillRect(i, i, 2, 2);
    }

    return ctx.createPattern(cnv, 'repeat');
}

function getFieldColor(
    cropType: CropType,
    type = FillType.Solid,
    encoding: string = 'rgba'
): string | [number, number, number, number] {
    if (encoding === 'hex') {
        return cropType.field_color;
    }

    const color = hexToRgb(cropType.field_color);

    switch (type) {
        case FillType.Solid:
            color[3] = 1;
            break;

        case FillType.Transparent:
            color[3] = 0;
            break;

        case FillType.Translucid:
            color[3] = 0.65;
            break;

        case FillType.TranslucidStrong:
            color[3] = 0.75;
            break;

        default:
            console.error('Unknown fillType');
            break;
    }

    return color;
}

function getCropTypeOutlineStyle(cropType: CropType, type: string): olStyle {
    if (!cropTypeOutlineStyles[cropType.id]) {
        const fillColor: [number, number, number, number] = getFieldColor(
            cropType,
            FillType.Translucid
        ) as [number, number, number, number];

        const outlineColor: [number, number, number, number] = getFieldColor(
            cropType,
            FillType.Solid
        ) as [number, number, number, number];

        const fill = new olFill({
            color: fillColor,
        });

        cropTypeOutlineStyles[cropType.id] = {
            focus: new olStyle({
                stroke: outlineStrokeStyles.focus,
                fill,
                zIndex: 3,
            }),
            hover: new olStyle({
                stroke: outlineStrokeStyles.hover,
                fill,
                zIndex: 3,
            }),
            vegetation: new olStyle({
                stroke: new olStroke({
                    color: outlineColor,
                    width: strokeWidth,
                }),
                fill: new olFill({
                    color: [0, 0, 0, 0],
                }),
                zIndex: 3,
            }),
            default: new olStyle({
                stroke: new olStroke({
                    color: outlineColor,
                    width: strokeWidth,
                }),
                fill,
                zIndex: 1,
            }),
        };
    }

    return cropTypeOutlineStyles[cropType.id][type];
}

function getHashFillStyle(colorArray: [number, number, number, number]) {
    return new olFill({ color: createHashPattern(colorArray) });
}
