import { Injectable } from '@angular/core';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import _ from 'lodash';

import { ApiService } from '@shared/services';
import { ClientsService } from '@modules/clients/services/clients.service';
import { getAgricultureYearFromDate } from '@shared/utils/utils';
import { Client, ClientSet } from '@shared/models/client';
import { CurrentYearData } from '@shared/models/overview';

const CHUNK_SIZE = 6;

@Injectable({
    providedIn: 'root',
})
export class OverviewService {
    /** Clients filtered by agricultural year */
    private FilteredClients: Record<string, Client[]> = {};

    /** Years in which clients were uploaded */
    AvailableYears: number[];

    /** Holds the current year data */
    CurrentYear: BehaviorSubject<CurrentYearData> = new BehaviorSubject<CurrentYearData>({
        year: 0,
        ClientsCount: 0,
        ClientsFailed: 0,
        ClientsLoaded: 0,
        ClientSets: [],
    });

    /** Clients at the moment the overview was loaded */
    OverviewClients: Client[] = [];

    /** Saved Data */
    YearlyData: Record<string, CurrentYearData> = {};

    constructor(private apiService: ApiService, private clientsService: ClientsService) {
        this.gatherClientData();
    }

    exportStats(): Observable<any> {
        const year = this.CurrentYear.getValue().year;

        return this.apiService.exportStats(year);
    }

    private gatherClientData(): void {
        this.clientsService.Clients.pipe(take(1)).subscribe((clients) => {
            if (!_.isEqual(this.OverviewClients, clients)) {
                this.OverviewClients = clients;
                this.FilteredClients = _(clients)
                    .sortBy('created_at', 'desc')
                    .uniqBy((client) =>
                        [
                            getAgricultureYearFromDate(client.last_uploaded_at),
                            client.fiscal_number,
                        ].join()
                    )
                    .groupBy((client) => getAgricultureYearFromDate(client.last_uploaded_at))
                    .value();

                this.AvailableYears = _(Object.keys(this.FilteredClients))
                    .map((stringYear) => +stringYear)
                    .filter(Boolean)
                    .sort()
                    .reverse()
                    .value();
            }

            if (!_.isEmpty(this.CurrentYear.getValue().year)) {
                this.fetchYearData();
            } else {
                this.fetchYearData(_.max(this.AvailableYears));
            }
        });
    }
    /** Refreshes the service data */
    refreshData(): void {
        this.gatherClientData();
    }

    /**
     * Returns client set data for the given client ids.
     * For each client, only the current year's set is considered.
     */
    private getClientSets(clientIds: number[] = []): Observable<ClientSet[]> {
        return this.apiService.getClientSets(clientIds);
    }

    /**
     * Clients are loaded in groups of 6 (configured by `CHUNK_SIZE`)
     * Updates the local ClientSets variable
     * Depending on the result, the ClientsLoaded and ClientsFailed are updated accordingly
     */
    private getClientSetsRequest(): Observable<any> {
        const selectedYearClients = this.FilteredClients[this.CurrentYear.getValue().year];

        const requests = _(selectedYearClients)
            .map((client) => client.id)
            .chunk(CHUNK_SIZE)
            .map((chunk) =>
                this.getClientSets(chunk).pipe(
                    tap(
                        (clientSets: ClientSet[]) => {
                            const data = this.CurrentYear.getValue();
                            this.CurrentYear.next({
                                ...data,
                                ClientSets: data.ClientSets.concat(clientSets),
                                ClientsLoaded: data.ClientsLoaded + _.size(chunk),
                            });
                        },
                        (error) => {
                            const data = this.CurrentYear.getValue();
                            this.CurrentYear.next({
                                ...data,
                                ClientsFailed: data.ClientsFailed + _.size(chunk),
                            });
                        }
                    )
                )
            )
            .value();

        return forkJoin(requests);
    }

    private fetchYearData(year = this.CurrentYear.getValue().year): void {
        // Gather Data from the backend
        this.CurrentYear.next({
            year: year,
            ClientsCount: _.size(this.FilteredClients[year]),
            ClientsFailed: 0,
            ClientsLoaded: 0,
            ClientSets: [],
        });

        this.getClientSetsRequest().pipe(take(1)).subscribe();
    }

    changeSelectedYear(availableYear: number): void {
        const currentYearData = this.CurrentYear.getValue();

        if (currentYearData.year === availableYear) {
            return;
        }

        // Store current values into the service
        this.YearlyData[currentYearData.year] = this.CurrentYear.getValue();

        // Already saved data
        if (!_.isEmpty(this.YearlyData[availableYear])) {
            this.CurrentYear.next(this.YearlyData[availableYear]);
            return;
        }

        this.fetchYearData(availableYear);
    }
}
